import Modal from "component/common/Modalv2";
import React, { FC } from "react";

interface ListModalProps {
  isOpen: boolean;
  onClose: any;
  content: any;
  heading: string;
}

const ListModal: FC<ListModalProps> = ({ isOpen, onClose, content, heading }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} className="w-[35rem] pb-2" headerClassName="bg-[#F0F4FD] py-4 rounded-t-lg -mt-5" header={<p className="font-bold">{heading}</p>}>
      <div className="p-5  max-h-[26rem] overflow-auto rounded-br-xl custom-scrollbar ">
        {content &&
          content.map((obj: any, index: number) => {
            return (
              <div key={index} className={`flex gap-3 items-center mb-3 ${index == content.length - 1 ? "pb-1" : "pb-3"} ${index !== content.length - 1 && "border-[#cccccc] border-dashed  border-b-[1px] "} `}>
                <p className="bg-[#C4C678] w-3 h-3"></p>
                <p className="font-bold text-sm ">{obj}</p>
              </div>
            );
          })}
      </div>
    </Modal>
  );
};

export default ListModal;
