import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { gethealthQualityDetailsV2 } from "services/request";
import ModalPopup from "./Modal";
import { useAppDispatch, useAppSelector } from "hooks/redux_toolkit";
import { handleHealthQualityData } from "store/user/user.slice";

function HealthPolicy() {
  const [showModal, setShowModal] = useState(false);
  const [policyDetails, setPolicyDetails] = useState<any>("");

  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const dataFetcher = async () => {
      const res = await gethealthQualityDetailsV2(params?.policyId);
      dispatch(handleHealthQualityData(res?.data));
    };
    dataFetcher();
  }, []);

  useEffect(() => {
    const policyDetails = async () => {
      const data = await gethealthQualityDetailsV2(params?.policyId);
      setPolicyDetails(data?.data);
    };
    policyDetails();
  }, []);

  useEffect(() => {
    setShowModal(true);
  }, []);

  const policyData = useAppSelector((state) => state.user.healthQualityData);
  console.log(policyData);

  const handleNavigate = () => {
    navigate("/policyholder-overview/" + params?.policyId);
  };

  return (
    <>
      <h1 className="text-xl font-extrabold block md:hidden">Your Health Policy Preview</h1>

      <div className="flex justify-center items-center lg:mt-8 mt-2">
        <div className=" lg:max-w-[850px] lg:border lg:border-gray-400 h-[800px] flex flex-col rounded-lg w-full">
          <div>
            <h1 className="text-xl font-extrabold p-5 lg:ml-6 hidden md:block">Your Health Policy Preview</h1>
          </div>

          <div className="border border-gray-400 p-3 lg:w-[750px] lg:ml-12 rounded-lg">
            <h3 className="text-gray-600 font-semibol ml-2">Insurance Type</h3>
            <div className="flex items-center border border-gray-300 lg:w-[550px] p-3 lg:m-2 rounded-lg bg-custom-gradient-g w-[full]">
              {/* <img src={"/assets/HealthBag.png"} className="w-[32px] h-[32px]  bg-white p-1" /> */}
              <div className="p-2 shadow-icon bg-white rounded-[10px]">
                <img src={"/assets/HealthBag.png"} className="w-[32px] h-[32px]" />
              </div>
              <h1 className="lg:text-lg lg:font-medium ml-2 font-bold">{policyDetails?.policyId?.insuranceCompanyId?.name}</h1>
            </div>
          </div>

          <div className="border border-gray-400 relative p-3 lg:w-[750px] lg:ml-12 mt-6 bg-[#EFFEE3] rounded-lg">
            <table className="w-full text-left border-collapse">
              <thead className="lg:hidden">
                <tr>
                  {policyDetails?.policyId?.policyName ? <th className="p-2">Plan Name</th> : ""}
                  {policyDetails?.docEyeOutput?.sa_cover_type ? <th className="p-2">Plan Type</th> : ""}
                </tr>
                <tr className="lg:hidden">
                  {policyDetails?.policyId?.policyName ? <td className="p-2">{policyDetails?.policyId?.policyName}</td> : ""}
                  {policyDetails?.docEyeOutput?.sa_cover_type ? <td className="p-2">{policyDetails?.docEyeOutput?.sa_cover_type}</td> : ""}
                </tr>
                <tr>
                  <th className="p-2">Policy Number</th>
                  {/* <th className="p-2">Group Name</th> */}
                </tr>
                <tr className="lg:hidden">
                  <td className="p-2">{policyDetails?.policyId?.policyNumber}</td>
                  {/* <td className="p-2">{policyDetails?.docEyeOutput?.policy_product_name}</td> */}
                </tr>
              </thead>

              <thead className="hidden lg:table-header-group">
                <tr>
                  {policyDetails?.policyId?.policyName ? <th className="p-2">Plan Name</th> : ""}
                  {policyDetails?.docEyeOutput?.sa_cover_type ? <th className="p-2">Plan Type</th> : ""}
                  <th className="p-2">Policy Number</th>
                  {/* <th className="p-2">Group Name</th> */}
                </tr>
              </thead>

              <tbody>
                <tr className="hidden lg:table-row">
                  {policyDetails?.policyId?.policyName ? <td className="p-2">{policyDetails?.policyId?.policyName}</td> : ""}
                  {policyDetails?.docEyeOutput?.sa_cover_type ? <td className="p-2">{policyDetails?.docEyeOutput?.sa_cover_type}</td> : ""}
                  <td className="p-2">{policyDetails?.policyId?.policyNumber}</td>
                  {/* <td className="p-2">Canara Bank</td> */}
                </tr>
              </tbody>
            </table>
            <img src="/assets/dotedVector.png" alt="" className="absolute right-0 bottom-0" />
          </div>

          <div className="border border-gray-400 relative p-3 lg:w-[750px] lg:ml-12 mt-6 bg-[#f2fcff] rounded-lg">
            <table className="w-full text-left border-collapse">
              <thead className="lg:hidden">
                <tr>
                  <th className="p-2">Premium Amoun</th>
                  {/* <th className="p-2">Sum Insured</th> */}
                  <th className="p-2">Policy Start Date</th>
                </tr>
                <tr className="lg:hidden">
                  <td className="p-2">{policyDetails?.policyId?.premiumAmount}</td>
                  <td className="p-2">{policyDetails?.docEyeOutput?.policy_start_date}</td>
                  {/* <td className="p-2">₹ 60,98,000</td> */}
                </tr>
                <tr>
                  {/* <th className="p-2">Policy Start Date</th> */}
                  <th className="p-2">Policy End Date</th>
                  <th className="p-2">No Claim Bonus Amt.</th>
                </tr>
                <tr className="lg:hidden">
                  {/* <td className="p-2">20/05/2015</td> */}
                  <td className="p-2">{policyDetails?.docEyeOutput?.policy_end_date}</td>
                  <td className="p-2">{policyDetails?.docEyeOutput?.no_claim_bonus || "-"}</td>
                </tr>
              </thead>

              <thead className="hidden lg:table-header-group">
                <tr>
                  <th className="p-2">Premium Amount</th>
                  {/* <th className="p-2">Sum Insured</th> */}
                  <th className=" p-2">Policy Start Date</th>
                  <th className=" p-2">Policy End Date</th>
                </tr>
              </thead>

              <tbody>
                <tr className="hidden lg:table-row">
                  <td className="p-2">{policyDetails?.policyId?.premiumAmount}</td>
                  <td className="p-2">{policyDetails?.docEyeOutput?.policy_start_date}</td>
                  <td className="p-2">{policyDetails?.docEyeOutput?.policy_end_date}</td>
                  {/* <td className="p-2">{policyDetails?.policyId?.premiumAmount}</td> */}
                </tr>
              </tbody>
            </table>

            <table className="w-full text-left border-collapse">
              <thead className="lg:hidden">
                <tr>
                  {/* <th className="p-2">Policy Renewal Date</th> */}
                  {/* <th className="p-2">No Claim Bonus Amt.</th> */}
                  <th className=" p-2">Premium Frequency</th>
                </tr>
                <tr className="lg:hidden">
                  {/* <td className="p-2">₹ 35,070</td> */}
                  {/* <td className="p-2">₹ 2,00,000</td> */}
                  <td className="p-2">{policyDetails?.docEyeOutput?.mode_of_payment}</td>
                </tr>
                <tr>
                  {/* <th className=" p-2">Restoration Amount</th> */}
                  {/* <th className=" p-2">Premium Frequency</th> */}
                </tr>
                <tr className="lg:hidden">
                  {/* <td className="p-2">₹ 3,00,000</td> */}
                  {/* <td className="p-2">Monthly</td> */}
                </tr>
              </thead>

              <thead className="hidden lg:table-header-group">
                <tr>
                  {/* <th className="p-2">Policy Renewal Date</th> */}
                  <th className="p-2">No Claim Bonus Amt.</th>
                  {/* <th className=" p-2">Restoration Amount</th> */}
                  <th className=" p-2">Premium Frequency</th>
                </tr>
              </thead>

              <tbody>
                <tr className="hidden lg:table-row">
                  {/* <td className="p-2">{policyDetails?.docEyeOutput?.policy_start_date}</td> */}
                  <td className="p-2">{policyDetails?.docEyeOutput?.no_claim_bonus || "-"}</td>
                  {/* <td className="p-2">{policyDetails?.docEyeOutput?.policy_start_date}</td> */}
                  <td className="p-2">{policyDetails?.docEyeOutput?.mode_of_payment}</td>
                </tr>
              </tbody>
            </table>
            <img src="/assets/dotedVector.png" alt="" className="absolute right-0 bottom-0" />
          </div>

          <div className="border border-gray-400 relative p-3 lg:w-[750px] lg:ml-12 mt-6 bg-[#ffffe4] rounded-lg">
            <table className="w-full text-left border-collapse">
              <thead className="lg:hidden">
                <tr>
                  <th className="p-2">Agent Name</th>
                  <th className="p-2">Agent Contact No.</th>
                </tr>
                <tr className="lg:hidden">
                  <td className="p-2">{policyDetails?.docEyeOutput?.agent_broker_name}</td>
                  <td className="p-2">{policyDetails?.docEyeOutput?.agent_broker_number}</td>
                </tr>
                <tr>
                  <th className=" p-2">Agent Code</th>
                </tr>
                <tr className="lg:hidden">
                  <td className="p-2">{policyDetails?.docEyeOutput?.agent_broker_code}</td>
                </tr>
              </thead>

              <thead className="hidden lg:table-header-group">
                <tr>
                  <th className="p-2">Agent Name</th>
                  <th className="p-2">Agent Contact No.</th>
                  <th className=" p-2">Agent Code</th>
                </tr>
              </thead>

              <tbody>
                <tr className="hidden lg:table-row">
                  <td className="p-2">{policyDetails?.docEyeOutput?.agent_broker_name}</td>
                  <td className="p-2">{policyDetails?.docEyeOutput?.agent_broker_number}</td>
                  <td className="p-2">{policyDetails?.docEyeOutput?.agent_broker_code}</td>
                </tr>
              </tbody>
            </table>
            <img src="/assets/dotedVector.png" alt="" className="absolute right-0 bottom-0" />
          </div>

          <div className="border border-gray-300 mt-10 text-center h-28 bg-white p-2">
            {/* <button onClick={() => navigate("/policyholder-overview/" + params?.policyId)} className="text-center mt-3 border border-gray-300 bg-orange-300 p-2 w-40 rounded-lg font-bold"> */}
            <button className="text-center mt-3 border border-gray-300 bg-orange-300 p-2 w-40 rounded-lg font-bold" onClick={handleNavigate}>
              Confirm
            </button>
          </div>
        </div>
      </div>
      <ModalPopup isOpen={showModal} onClose={() => setShowModal(!showModal)} />
    </>
  );
}

export default HealthPolicy;
