import React from "react";

const BottomContent: React.FC<any> = ({ children }) => {
  return (
    <nav className="fixed bottom-0 right-0 left-0 bg-white shadow-polifyx rounded-t-lg lg:pt-4">
      <div className="flex justify-center items-center mb-4">{children}</div>
    </nav>
  );
};

export default BottomContent;
