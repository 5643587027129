import React from "react";
import ComponentWrapper from "../pages";
// import ExistingPolicy from "../pages/ExistingPolicy";
import InsurancePolicyDetails from "../pages/insurancePolicyDetail";
import LoadingScreen from "../pages/loadingscreen";
import OtpWrapper from "../pages/otp";
import PolicyDetails from "../pages/policyDetails";
import UploadImage from "../pages/policyDetails/UploadImage";
import Questionare from "../pages/questionare/Questionare";
import ResultScreen from "../pages/result";
import TryAgain from "../pages/somthingWrong";
import UserPolicyDetails from "pages/userdetails/UserPolicyDetails";
import PolicyHolderDetails from "pages/policyHolderDetails";
import PolicyInProgress from "pages/ExistingPolicy/policyInProgress";
import HealthPolicy from "pages/HealthPolicy";
// import PolicyInProgress from "pages/ExistingPolicy/policyInProgress";
//import BenefitSummary from "pages/benefitsSummary/BenefitSummary";
// import PolicyHolder_InsuredOverview from "pages/policyHolderInsuredOverview";
import ExistingPolicy from "pages/ExistingPolicy";
import OverView from "pages/Overview";
import NotFound from "pages/NotFound";
import BenefitSummary from "pages/benefitsSummary/BenefitSummary";
import DetailedResult from "pages/DetailedResult/DetailedResult";
interface RouteType {
  path: string;
  element?: React.ReactNode;
  privateRoute: boolean;
  children?: RouteType[];
  index?: any;
}

const routes: RouteType[] = [
  {
    path: "/",
    privateRoute: true,
    children: [
      {
        index: true,
        path: "/",
        element: (
          <ComponentWrapper>
            <UserPolicyDetails />
          </ComponentWrapper>
        ),
        privateRoute: true
      },
      {
        index: true,
        path: "/kyp-result/:id",
        element: (
          <ComponentWrapper>
            <DetailedResult />
          </ComponentWrapper>
        ),
        privateRoute: true
      },

      {
        index: true,
        path: "/policyholder-overview/:policyId",
        element: (
          <ComponentWrapper>
            <OverView />
          </ComponentWrapper>
        ),
        privateRoute: true
      },
      {
        index: true,
        path: "/otp",
        element: (
          <ComponentWrapper>
            <OtpWrapper />
          </ComponentWrapper>
        ),
        privateRoute: true
      },
      {
        index: true,
        path: "/question/:policyId",
        element: (
          <ComponentWrapper>
            <Questionare />
          </ComponentWrapper>
        ),
        privateRoute: true
      },
      {
        index: true,
        path: "/policy",
        element: (
          <ComponentWrapper>
            <PolicyDetails />
          </ComponentWrapper>
        ),
        privateRoute: true
      },
      {
        index: true,
        path: "/existing-policy",
        element: (
          <ComponentWrapper>
            <ExistingPolicy />
          </ComponentWrapper>
        ),
        privateRoute: true
      },
      {
        index: true,
        path: "/policyholder-details",
        element: (
          <ComponentWrapper>
            <PolicyHolderDetails />
          </ComponentWrapper>
        ),
        privateRoute: true
      },
      {
        index: true,
        path: "/upload",
        element: (
          <ComponentWrapper>
            <UploadImage />
          </ComponentWrapper>
        ),
        privateRoute: true
      },
      {
        index: true,
        path: "/loading",
        element: (
          <ComponentWrapper>
            <LoadingScreen />
          </ComponentWrapper>
        ),
        privateRoute: true
      },
      {
        index: true,
        path: "/result/:policyId",
        element: (
          <ComponentWrapper className="!p-0">
            <ResultScreen />
          </ComponentWrapper>
        ),
        privateRoute: true
      },
      {
        index: true,
        path: "/policy-details/:_id",
        element: (
          <ComponentWrapper>
            <InsurancePolicyDetails />
          </ComponentWrapper>
        ),
        privateRoute: true
      },
      {
        index: true,
        path: "/benefits-summary/:policyId",
        element: (
          <ComponentWrapper>
            <BenefitSummary />
          </ComponentWrapper>
        ),
        privateRoute: true
      },
      {
        index: true,
        path: "/health-policy/:policyId",
        element: (
          <ComponentWrapper>
            <HealthPolicy />
          </ComponentWrapper>
        ),
        privateRoute: true
      },

      {
        index: true,
        path: "/tryAgain",
        element: (
          <ComponentWrapper>
            <TryAgain />
          </ComponentWrapper>
        ),
        privateRoute: true
      },
      {
        index: true,
        path: "/policy-progress/:policyId",
        element: (
          <ComponentWrapper>
            <PolicyInProgress />
          </ComponentWrapper>
        ),
        privateRoute: true
      },
      {
        index: true,
        path: "*",
        element: (
          <ComponentWrapper>
            <NotFound />
          </ComponentWrapper>
        ),
        privateRoute: false
      }
    ]
  }
];

const allRoutes: RouteType[] = routes;
export default allRoutes;
