export const Apis = {
  companyList: "/insurance_company/getByPolicyId",
  userDetails: "/user/createUser",
  policyDetails: "/policy/getPolicyDocEyeData",
  getHealthQuality: "/app/v1/healthQuality/get",
  healthQualityResult: "/app/v1/healthQuality/result",
  getHealthQualityResult: "/app/v1/healthQualityResult",
  couponCheck: "/coupon/check",
  kypTransaction: "/transaction/kyp",
  uploadPolicy: "/policy/v2",
  appData: "/app/data",
  updatePolicy: "/policy",
  getLifeQusetions: "/app/v1/questions",
  lifeQualityCheck: "/app/v1/qualityCheck",
  lifeQualityCheckResult: "/app/qualityCheck/result",
  verifyOtp: "/user/verifyOtp",
  policyHolder: "/user/relation",
  addUser: "/user/v1/relation",
  getHealthQualityV2: "/app/v2/healthQuality/get",
  getBenefitSummary: "/app/v1/getBenefitsSummary/",
  getUserProfile: "/user/profile",

  // partners api

  getPartnerDetails: "/api/v1/user/getPartnerDetails"
};
