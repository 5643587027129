import React, { useEffect, useState } from "react";
import Heading1 from "../../component/semantic/Heading1";
// import ContainerOne from "../../component/common/ContainerOne";
import FormInput from "../../component/semantic/InputOne";
import BottomContent from "../../layout/BottomContent";
import DarkButton from "../../component/semantic/DarkButton";
import { useDispatch } from "react-redux";
import { handlePartnerDetails, handrelationDetails } from "../../store/user/user.slice";
import { addNewUser, getAppData, getPartnerData, getPolicyHolder, getUserDetails } from "../../services/request";
import useToastWrapper from "../../hooks/useToastWrapper";
import { getDataInLocalStorage, setDataInLocalStorage } from "../../helpers/Utils";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { CgAdd } from "react-icons/cg";
import { IoCloseCircleSharp } from "react-icons/io5";

const UserPolicyDetails: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showToast } = useToastWrapper();
  const searchParams = new URLSearchParams(window.location.search);
  const [errors, setErrors] = useState<any>({});
  const [partnerData, setPartnerData] = useState<any>({});
  const [selectedPolicyHolder, setselectedPolicyHolder] = useState<any>("");

  const initialForm = {
    polHolderName: "",
    polHolderPhone: "",
    polHolderEmail: ""
  };
  const [policyHolderDetailsForm, setPolicyHolderDetailsForm] = useState(false);
  const policyHolderDetailsFormHandle = () => {
    setPolicyHolderDetailsForm(true);
  };
  function closeIconHandler() {
    setPolicyHolderDetailsForm(false);
  }
  const [policyHolderDetails, setPolicyHolderDetails] = useState<any>(initialForm);
  const fetchPartnerHandler = (src: string) => {
    getPartnerData(src)
      .then((res: any) => {
        let partnerData = {};
        if (res?.data?._id) {
          partnerData = {
            userCreatedBy: res?.data?._id,
            name: res?.data?.name,
            partnerLogo: res?.data?.partnerLogo,
            kypFees: 99
          };
        }
        setDataInLocalStorage("src", src);
        setPartnerData(partnerData);
        dispatch(handlePartnerDetails(partnerData));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    const src = searchParams.get("src");
    const partnerIdInLocalStorage = getDataInLocalStorage("src");
    if (src) {
      // console.log("--------->>>", searchParams.get("src"));
      fetchPartnerHandler(src);
    } else if (partnerIdInLocalStorage) {
      fetchPartnerHandler(partnerIdInLocalStorage);
    }
    if (searchParams.get("phone") || searchParams.get("email") || searchParams.get("name")) {
      setPolicyHolderDetails({
        ...(searchParams.get("phone") && { polHolderPhone: searchParams.get("phone") }),
        ...(searchParams.get("email") && { polHolderEmail: searchParams.get("email") }),
        ...(searchParams.get("name") && { polHolderName: searchParams.get("name") })
      });
    }
    fetchConstantData();
  }, []);
  const validateDetails = () => {
    const errObj: any = {};

    // Phone number validation
    const phoneRegex = /^[0-9]{10}$/; // Assumes 10-digit phone number
    if (!phoneRegex.test(policyHolderDetails.polHolderPhone)) {
      errObj.polHolderPhone = "should be a 10-digit number";
    }
    const phoneRegex1 = /^[6789]\d{9}$/; // Assumes 10-digit phone number
    if (!phoneRegex1.test(policyHolderDetails.polHolderPhone)) {
      errObj.polHolderPhone = "should be a valid";
    }
    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email regex
    if (!emailRegex.test(policyHolderDetails.polHolderEmail)) {
      errObj.polHolderEmail = "should be a valid email address";
    }
    // Check if any field is empty
    Object.keys(policyHolderDetails).forEach((val) => {
      if (!policyHolderDetails[val]) {
        errObj[val] = "is required !!";
      }
    });
    if (Object.keys(errObj).length > 0) {
      setErrors(errObj);
      return true;
    }
    return false;
  };
  const fetchConstantData = async () => {
    const resp = await getAppData();
    if (resp && resp.success) {
      dispatch(handrelationDetails(resp.data.relations));
    }
  };
  const nextHandler = async () => {
    try {
      // after selecting Policy Holder
      if (policyHolderDetailsForm == false && selectedPolicyHolder) {
        localStorage.setItem("selectedPolicyHolder", selectedPolicyHolder?._id);
        navigate("/policy");
      } else if (policyHolderDetailsForm == false && !selectedPolicyHolder) {
        showToast("Please select Policy Owner Name");
      }
      if (policyHolderDetailsForm == true) {
        const isValid = validateDetails();
        if (isValid) {
          return;
        }

        let resp;
        if (partnerData?.userCreatedBy) {
          resp = await getUserDetails({ ...policyHolderDetails, polHolderEmail: policyHolderDetails?.polHolderEmail?.toLowerCase(), ...{ userCreatedBy: partnerData?.userCreatedBy } });
        } else {
          resp = await getUserDetails({ ...policyHolderDetails, polHolderEmail: policyHolderDetails?.polHolderEmail?.toLowerCase() });
        }
        if (!resp.success) return;
        if (resp?.data) {
          // dispatch(handleCurrentUser(resp?.data?.userObj));
          const res = await addNewUser(resp?.data?.userObj);
          if (res?.success) navigate("/policy");
          return;
        } else {
          showToast("Something went wrong !!!", "error");
        }
      }
    } catch (e) {
      showToast("Something went wrong !!!", "error");
    }
  };
  const [hasStartedTyping, setHasStartedTyping] = useState(false);

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (!hasStartedTyping && value.trim() !== "") {
      setHasStartedTyping(true);
    }

    if (!hasStartedTyping && value.startsWith(" ")) {
      return;
    }
    if (value.length == 0) {
      setHasStartedTyping(false);
    }

    setPolicyHolderDetails((prevDetails: any) => ({
      ...prevDetails,
      [name]: value
    }));

    delete errors[e.target.name];
    // setPolicyHolderDetails({ ...policyHolderDetails, [e.target.name]: e.target.value });
  };
  const customStyles = {
    placeholder: (provided: any) => ({
      ...provided,
      color: "black",
      padding: "6px"
    }),
    menu: (provided: any) => ({
      ...provided,
      zIndex: 1000
    })
  };
  const [policyHolder, setPolicyHolder] = useState([]);
  async function getPolicyHolderDataFun() {
    const res = await getPolicyHolder();
    setPolicyHolder(res.data);
    // console.log(res.data);
    // console.log(policyHolder)
  }
  useEffect(() => {
    getPolicyHolderDataFun();
  }, []);
  return (
    <div>
      <Heading1 className="font-extrabold text-sm block md:hidden mx-4">Policyholder Details</Heading1>
      <div className="max-w-[850px] min-h-[policyHolderDetailsForm ? 550px : 220px] mx-4 md:mx-auto bg-white border border-[#D8DDE9] mt-3 rounded-xl ">
        <p className="font-bold  text-black text-sm block md:hidden w-full py-4 pl-4 shadow-md overflow-hidden bg-white z-30 rounded-t-xl">Who Are You Uploading The Policy For?</p>
        <div className="p-4 pt-0 md:pt-4 md:p-10 flex flex-col justify-between w-full bg-[#FFFFFF] rounded-b-xl mt-2 md:mt-0  custom-scrollbar">
          <div>
            <Heading1 className="font-extrabold text-sm hidden md:block">Policyholder Details</Heading1>
            <p className="font-bold mt-4 text-black hidden md:block">Who Are You Uploading The Policy For?</p>
            <div className="flex flex-wrap -mx-2 mb-5 mt-4">
              <div className="w-full md:w-1/2 px-2 z-20">
                <p className="font-bold text-xs text-gray-800 mb-2 z-20">Select Policyholder *</p>
                <Select name="" className="text-black z-20 " classNamePrefix="select" isSearchable={false} value={selectedPolicyHolder} placeholder="Policy Owner Name" styles={customStyles} onChange={(e: any) => setselectedPolicyHolder(e)} options={policyHolder} getOptionLabel={(option: any) => option?.name} getOptionValue={(option: any) => option?._id} />
              </div>
              <p className="block md:hidden mt-1 m-auto text-center text-base">OR</p>
              <div className="w-full md:w-1/2 px-2 mt-1 md:mt-7 flex justify-center md:justify-end">
                <button className=" border-2 rounded-md border-gray-500 flex justify-center items-center w-full  md:px-8 py-2 md:py-2 lg:px-8 font-bold text-sm  " onClick={policyHolderDetailsFormHandle}>
                  Add User &nbsp;
                  <CgAdd />{" "}
                </button>
              </div>
            </div>
            {policyHolderDetailsForm && (
              <div>
                <hr className="w-full my-8 border-t border-gray-300" />
                <div className=" flex justify-end">
                  <IoCloseCircleSharp className="cursor-pointer " onClick={closeIconHandler} />
                </div>
                <div className="flex flex-wrap -mx-2">
                  <div className="w-full md:w-1/2 px-2">
                    <FormInput error={errors?.polHolderName ? `Policyholder Name ${errors?.polHolderName}` : ""} value={policyHolderDetails.polHolderName} name="polHolderName" type="text" placeholder="Policyholder Name" label="Policyholder Name *" onChange={changeHandler} />
                  </div>
                  <div className="w-full md:w-1/2 px-2 mt-4 md:mt-0">
                    <div className="relative">
                      <FormInput error={errors?.polHolderPhone ? `Policyholder Mobile ${errors?.polHolderPhone}` : ""} value={policyHolderDetails.polHolderPhone} name="polHolderPhone" type="tel" placeholder="Policyholder Mobile" label="Mobile Number *" maxLength={10} onChange={changeHandler} className="border w-full p-2 rounded-md border-gray-300" />
                      <span className="absolute right-2 top-1">
                        <img src={"/assets/mobile.png"} className=" mt-7" alt="Mobile Icon" />
                      </span>
                    </div>
                  </div>
                  <div className="w-full md:w-1/2 px-2 mt-4">
                    <FormInput error={errors?.polHolderEmail ? `Policyholder Email ${errors?.polHolderEmail}` : ""} value={policyHolderDetails.polHolderEmail} name="polHolderEmail" type="text" placeholder="Policyholder Email" label="Email Id *" onChange={changeHandler} />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <hr className="w-full my-2 border-t border-gray-300 hidden md:block" />
        <div className="justify-center bg-white shadow-navbar w-full mt-4 p-4 hidden md:flex rounded-xl">
          <DarkButton label="CONFIRM" onclick={nextHandler} color="polifyx" buttonClassName="px-20" />
        </div>
        <BottomContent>
          <DarkButton label="CONFIRM" onclick={nextHandler} color="polifyx" buttonClassName="px-20" className="block md:hidden" />
        </BottomContent>
      </div>
    </div>
  );
};
export default UserPolicyDetails;
