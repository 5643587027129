import React, { useEffect, useState } from "react";
import { GoMoveToBottom } from "react-icons/go";
import { FaRegCopy } from "react-icons/fa";
import { CiShare2 } from "react-icons/ci";
import { useAppSelector } from "hooks/redux_toolkit";
import { useNavigate, useParams } from "react-router-dom";
import { getPolicyListData } from "services/request";
import { useDispatch } from "react-redux";
import { handleSelectPolicyListData } from "store/user/user.slice";
import MailCopyModal from "./MailCopyModal";

const DetailedResult = () => {
  const details = useAppSelector((state) => state.user.policyListDetails);
  const { PolicyHolderName, InsuranceCompanyName, PolicyName, ResultTitle, detailedKypPdfUrl, Draft } = details;
  const param = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getPolicyListData(param.id);
      dispatch(handleSelectPolicyListData(data?.data));
    };
    if (param.id) {
      fetchData();
    }
  }, [param]);

  const handleDownloadPdf = (url: any) => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "KYP_policyFile.pdf");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleEmailClick = () => {
    const toEmail = Draft?.to;
    const bccEmail = Draft?.bcc;
    const subject = Draft?.subject;
    const body = Draft?.body;

    const mailtoLink = `mailto:${toEmail}?bcc=${bccEmail}&subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    window.location.href = mailtoLink;
  };

  const getBgColor = (ResultTitle: string) => {
    switch (ResultTitle) {
      case "Healthy":
        return "bg-gradient-to-b from-[#F8FEFB] to-[#E9FDF3]";
      case "Critical":
        return "bg-gradient-to-b from-[#FEFCF8] to-[#FDF0E9]";
      case "Needs Attention":
        return "bg-gradient-to-b from-[#FEFCF8] to-[#FDF0E9]";
      default:
        return "bg-[#3d4a67]";
    }
  };
  const textColor = (ResultTitle: string) => {
    switch (ResultTitle) {
      case "Healthy":
        return "text-[#2F9D7F]";
      case "Critical":
        return "text-[#E55454]";
      case "Needs Attention":
        return "text-[#F78C4F]";
    }
  };
  const conditionBasedText = (ResultTitle: string) => {
    switch (ResultTitle) {
      case "Healthy":
        return (
          <div className="flex gap-1 items-start">
            <span className="flex flex-wrap gap-3">
              Based on your responses, there are no errors in your policy’s personal information
              <span className="flex gap-2 items-center">
                <img src="/assets/tick.svg" alt="Congratulations" className="w-5" />
                <span>Congratulations!</span>
              </span>
            </span>
          </div>
        );
      case "Critical":
        return "Based on your responses, there are few critical errors in your policy’s personal information.";
      case "Needs Attention":
        return "Based on your responses, there are few errors in your policy’s personal information.";
    }
  };

  const handleNavigate = () => {
    navigate("/existing-policy");
  };
  const data = ["Avoid policies with room rent caps.", "Consider buying a top-up policy if your sum insured is low.", "Pay premiums regularly and on time.", "After five years of continuous payments (moratorium period), your insurer cannot reject claims due to non-disclosure or misrepresentation, except in cases of proven fraud.", "For a detailed consultation with our experts, you can email us at polifyx@insurancesamadhan.com"];
  return (
    <div className="w-full md:w-[80%] lg:w-[68%] m-auto md:border border-[#D8DDE9] pt-0 md:pt-10 rounded-lg ">
      <div className="w-full md:w-[90%] md:m-auto md:mt-4">
        <div className={`relative ${getBgColor(ResultTitle)} flex items-center`}>
          <img src="/assets/KYP_listBGImg.png" alt="KYP_BGImg" className="absolute inset-0 w-full h-full object-cover" />
          <div className="relative z-10 flex items-center mx-3 md:px-7 py-7">
            <img src="/assets/illus.svg" alt="" className="mr-3" />
            <div className="pl-4">
              <h1 className="font-extrabold text-xl">{PolicyHolderName}</h1>
              <h3 className="text-base font-bold">{InsuranceCompanyName}</h3>
              <p className="text-sm">{PolicyName}</p>
            </div>
          </div>
        </div>

        <div className="w-[95%] m-auto md:w-full">
          <div className="mt-7">
            <h1 className={`font-extrabold text-2xl ${textColor(ResultTitle)}`}>{ResultTitle}</h1>
            <p className="text-base font-medium">{conditionBasedText(ResultTitle)}</p>
          </div>

          {ResultTitle !== "Healthy" && (
            <>
              <div className="flex items-center mt-4 ">
                <img src="/assets/advice.png" alt="" />
                <p className="text-base	font-medium	mr-7">Protect yourself by notifying your insurance company of any discrepancies in your policy.</p>
              </div>

              <p className="font-medium text-base	mt-4	">
                Send the correct information to your insurer using your <span className="font-bold text-md">Registered Email ID</span>
              </p>
              <div className="flex mt-7 gap-4">
                <button onClick={handleEmailClick} className="flex items-center rounded-md text-sm font-bold px-6 py-2 border border-[#A486A4] bg-[#F5EFF5] text-[#5B3A5B]">
                  <CiShare2 /> &nbsp;
                  <span>Share via Gmail</span>
                </button>
                <button onClick={() => setShowModal(!showModal)} className="flex items-center rounded-md text-sm font-bold px-6 py-2 border border-[#8496BE] bg-[#F0F4FD] text-[#556384] ">
                  <FaRegCopy /> &nbsp; <span>Copy Mail Content</span>
                </button>
              </div>
            </>
          )}
          {detailedKypPdfUrl && (
            <div className="flex items-center mt-7 border border-[#F1F8F5] relative rounded-lg">
              <img src="/assets/pdf_logo.svg" alt="" className="md:w-[18%]" />
              <div className="flex flex-col  xl:flex-row w-[60%] ml-4 pr-4 ">
                <p className="text-sm md:text-base pt-5 pb-2	font-bold w-[90%] min-w-[75%] m-auto">Download your Know Your Policy summary as a PDF for your records.</p>
                <div className="flex items-center">
                  <button className="flex items-center  w-44 mb-2 mt-0 md:mt-2 ml-6 md:ml-0 lg:ml-11 font-bold text-xs  text-[#2f9d7f]  gap-1 border border-[#2F9D7F] px-6 py-2 rounded-3xl" onClick={() => handleDownloadPdf(detailedKypPdfUrl)}>
                    <GoMoveToBottom className="font-extrabold text-lg" /> <span className="w-full whitespace-nowrap">Download PDF</span>
                  </button>
                </div>
              </div>
              <img src="/assets/Group.png" alt="" className="absolute bottom-0 right-0 w-[10%] m-2" />
            </div>
          )}
          <img src="/assets/recommendation.png" alt="" className="w-full block md:hidden" />

          <img src="/assets/recommendation.svg" alt="" className="w-full hidden md:block" />
          <div className=" ">
            {data?.map((item, ind) => (
              <div key={ind} className="flex items-start text-base md:text-lg gap-4 pb-2 last:pb-5">
                <img src="/assets/RightTickVector.png" alt="" className="mt-2" />
                <p className="ml-2">{item}</p>
              </div>
            ))}
          </div>

          {ResultTitle !== "Healthy" && (
            <div className="text-sm font-medium hidden md:block border relative  border-[#FFE5C7] bg-[#FEF2E5] px-4 py-3 rounded-md my-7">
              <div className="relative top-0 left-[2px] text-center text-sm	font-bold -mt-3 -ml-[15px] ">
                <img src="/assets/disclaimer.svg" alt="DisclaimerImg" />
                <p className="absolute -mt-6 ml-7">Disclaimer</p>
              </div>
              <p className="mt-6">Please note that correcting inaccurate or missing information related to your policy may result in a change in premium or, in certain cases, termination of the policy contract.</p>
            </div>
          )}
        </div>
      </div>

      <div className="w-full py-5 block -mb-20 md:mb-0 shadow-[0_-4px_4px_-1px_rgba(0,0,0,0.3)]">
        <div className="w-[80%] m-auto flex justify-center gap-10 ">
          {/* <button className="text-sm px-11 py-3 border border-[#000000] rounded-lg font-bold" onClick={handleNavigate}>
            Go To Home
          </button> */}
          {/* <button className="bg-gradient-to-b from-[#FDCC50] to-[#FAAA52] text-sm px-9 py-3 rounded-lg font-bold">Check Another Policy</button> */}
          <button className="bg-gradient-to-b from-[#FDCC50] to-[#FAAA52] text-sm px-9 py-3 rounded-lg font-bold" onClick={handleNavigate}>
            Go To Home
          </button>
        </div>
      </div>
      <MailCopyModal isOpen={showModal} onClose={() => setShowModal(!showModal)} mailDetails={Draft} />
    </div>
  );
};

export default DetailedResult;
