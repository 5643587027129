import Modal from "component/common/Modalv2";
import React, { FC } from "react";
import { FaRegCopy } from "react-icons/fa";

interface ModalProps {
  isOpen: boolean;
  onClose: any;
  mailDetails: {
    to: string;
    bodyHtml: string;
  };
}

const MailCopyModal: FC<ModalProps> = ({ isOpen, onClose, mailDetails }) => {
  const handleCopyToClipboard = () => {
    if (mailDetails?.bodyHtml) {
      const tempElement = document.createElement("div");
      tempElement.innerHTML = mailDetails.bodyHtml;
      const plainText = tempElement.innerText;

      navigator.clipboard.writeText(plainText);
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} className="w-full md:w-[70%] lg:w-[30%]">
      <p className="px-5 pb-3 font-semibold">Please copy and paste the text below to email your insurance company: {mailDetails?.to} from your registered email id.</p>
      <p dangerouslySetInnerHTML={{ __html: mailDetails?.bodyHtml }} className="px-5 py-4 text-sm font-semibold bg-[#F0F4FD]"></p>
      <div className="flex justify-center py-5 gap-2 items-center cursor-pointer" onClick={handleCopyToClipboard}>
        <FaRegCopy />
        <p>Copy to Clipboard</p>
      </div>
    </Modal>
  );
};

export default MailCopyModal;
