import React, { useState } from "react";
import { FaCheck } from "react-icons/fa";
import ListModal from "./ListModal";
import SubLimitModal from "./SubLimitModal";

interface BenefitContentProps {
  selectedTab: any;
  contentData: any;
}

const BenefitContent: React.FC<BenefitContentProps> = ({ selectedTab, contentData }) => {
  const getActiveTab = (selectedTab: any) => {
    switch (selectedTab) {
      case "Hosp. Benefits":
        return "/assets/benefitsLogo.svg";
      case "Other Benefits":
        return "/assets/otherLogo.svg";
      case "Waiting Period":
        return "/assets/waitingLogo.svg";
      case "Exclusion":
        return "/assets/exclusionLogo.svg";
    }
  };
  const getActiveTabColor = (selectedTab: any) => {
    switch (selectedTab) {
      case "Hosp. Benefits":
        return "bg-[#F3FCFF] ";
      case "Other Benefits":
        return "bg-[#EFFEE3]";
      case "Waiting Period":
        return "bg-[#FEFFE3]";
      case "Exclusion":
        return "bg-[#FFF3F1] ";
    }
  };
  const getActiveTabBorder = (selectedTab: any) => {
    switch (selectedTab) {
      case "Hosp. Benefits":
        return "bg-[#F3FCFF] border-[#0B7A9F]";
      case "Other Benefits":
        return "bg-[#EFFEE3] border-[#95B47C]";
      case "Waiting Period":
        return "bg-[#FEFFE3] border-[#C4C678]";
      case "Exclusion":
        return "bg-[#FFF3F1] border-[#E5C4C0]";
    }
  };

  const [showModals, setShowModal] = useState<any>({ show: false, key: "" });

  const renderObject = (obj: any) => {
    return (
      <ul>
        {Object.entries(obj).map(([key, value]) => {
          if (!value || Array.isArray(value)) return;
          if (typeof value === "object" && value !== null) {
            return <li key={key}>{renderObject(value)}</li>;
          }
          return (
            <li key={key}>
              {key === "key" ? (
                <span className="flex gap-3 items-center" onClick={() => setShowModal({ show: true, key: value })}>
                  <FaCheck className={`${selectedTab.name === "Hosp. Benefits" ? "text-[#0B7A9F]" : selectedTab.name === "Other Benefits" ? "text-[#95B47C]" : "text-[#C4C678]"} font-[bold] `} />
                  <span dangerouslySetInnerHTML={{ __html: value }} />
                  {typeof value === "string" && value.includes("<button>") && <img src="/assets/benefit-button.svg" />}
                </span>
              ) : (
                <>
                  <span className={`flex gap-3 `} onClick={() => setShowModal({ show: true, key: value })}>
                    <p className="pl-[25px]" dangerouslySetInnerHTML={{ __html: value }}></p>
                    {typeof value === "string" && value.includes("<button>") && <img src="/assets/benefit-button.svg" />}
                  </span>

                  <p className="border-b-[1px]  my-3 border-[radial-gradient(44.1% 255.93% at 51.25% 100%, rgba(99, 99, 99, 0.5) 0%, rgba(239, 239, 239, 0.5) 100%)] "></p>
                </>
              )}
            </li>
          );
        })}
      </ul>
    );
  };
  return (
    <div className={`md:w-[770px] w-auto bg-[#ffffff] shadow-icon  md:mt-0 mb-[1%] border-[1px] rounded-lg ${getActiveTabBorder(selectedTab)} mt-[3%] `}>
      <div className="">
        <div className={` flex gap-2 p-[2.5%] rounded-tl-lg rounded-tr-lg ${getActiveTabColor(selectedTab.name)}`}>
          <img src={getActiveTab(selectedTab.name)} alt="Hosp. Benefits Tab" />
          <span className="text-lg font-extrabold ">{selectedTab.name === "Hosp. Benefits" ? "Hospitalisation Benefits" : selectedTab.name === "Other Benefits" ? "Other Benefits" : selectedTab.name === "Waiting Period" ? "Waiting Period & Sub-limit" : "Exclusion"}</span>
        </div>

        {selectedTab?.key !== "exclusions" ? (
          <ul className="px-6 py-2">{contentData ? renderObject(contentData) : ""}</ul>
        ) : (
          <ul className="px-6 py-2 space-y-3">
            {contentData.map((obj: any, index: number) => (
              <div key={index} className="flex gap-3 items-center">
                <img src="/assets/cross-icon.svg" />
                <p className="font-medium">{obj}</p>
              </div>
            ))}
          </ul>
        )}
      </div>
      <ListModal isOpen={showModals.show && (showModals?.key.includes("Treatment") || showModals?.key.includes("ailments"))} heading={showModals?.key.includes("Treatment") ? "Modern Treatment Coverage" : showModals?.key.includes("ailments") ? "List of Named Ailments" : ""} onClose={() => setShowModal({ show: false, key: "" })} content={showModals?.key.includes("Treatment") ? contentData?.MODERN_TREATMENT_COVERAGE_LIST : contentData?.NAMED_AILMENT_LIST} />
      <SubLimitModal isOpen={showModals.show && showModals?.key.includes("sub-limits")} heading="Sub-Limits (Modern Treatment Coverage)" onClose={() => setShowModal({ show: false, key: "" })} content={contentData?.MODERN_TREATMENT_SUB_LIMITS || []} />
    </div>
  );
};

export default BenefitContent;
