import React from 'react';
// import { HiArrowLongRight } from "react-icons/hi2";


interface NextButtonProps {
  nextTab: string;
  onNextClick: () => void;
}

const NextButton: React.FC<NextButtonProps> = ({ nextTab, onNextClick }) => {
  return (
    <div className="w-[100%] flex p-4 gap-auto bottom-0 md:ml-0 -ml-2 md:shadow-none shadow-icon md:relative fixed rounded-tl-xl rounded-tr-xl bg-white md:rounded-br-lg md:rounded-bl-lg md:rounded-tl-none md:rounded-tr-none md:border-[1px] md:border-t-0">
    <div className='text-gray-700 text-[14px] md:w-[89%] w-[100%] md:ml-4 '>
    <span className='flex items-center font-bold'> <span >Next</span>&nbsp; <img src="/assets/Vector.svg" alt="BackArrow" /></span>
    <p className='text-[gray] '>{nextTab}</p>
    </div>
      <div
        className="flex items-center bg-icon text-white rounded-lg px-4 py-2 cursor-pointer w-14 h-11 "
        onClick={onNextClick} // Next tab change
      >
        {/* <img src="assets/nextBtn.svg" alt="nextBtn" className='relative flex justify-center w-[58px] h-[44px]'/> */}
        <img src="/assets/Vector.svg" alt="BackArrow" />
      </div>
    </div>
  );
};

export default NextButton;
