import FormInput from "component/semantic/InputOne";
import { overviewConstants } from "helpers/Utils";
import React, { FC, useEffect, useRef, useState } from "react";

interface OverViewCardProps {
  title: string;
  details: any;
  id: string;
  values: any;
  setFieldValue: any;
  handleChange: any;
}

const OverViewCard: FC<OverViewCardProps> = ({ title, details, id, values, setFieldValue, handleChange }) => {
  const [openedSection, setOpenedSection] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const [editableField, setEditTableField] = useState<any>([]);

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [openedSection, editableField]);

  const contentRef: any = useRef();

  return (
    <>
      <div className=" border-[1px]  rounded-[5px]  overflow-hidden m-1 md:m-4 my-5 ">
        <div className="flex shadow-xl rounded-[5px] h-14 bg-[#EBEFF6]   justify-between items-center px-1 md:px-6 py-2 cursor-pointer" onClick={() => setOpenedSection(!openedSection)}>
          <span className="flex items-center gap-3">
            <p className="font-extrabold">{title}</p>
            {id === "insuredDetails" && details?.docEyeOutput?.insuredDetails.length && <p className="bg-[#94AACF] px-1 text-white text-sm font-bold rounded-md">{details?.docEyeOutput?.insuredDetails.length}</p>}
          </span>
          <img src={"/assets/arrow-circle.svg"} alt="arrow icon" className={`cursor-pointer transition-all duration-500 ${openedSection ? "rotate-0" : "rotate-180"}`} />
        </div>
      </div>
      <div
        ref={contentRef}
        className={`transition-[height] ease-in-out duration-500 overflow-scroll px-1 md:px-6 space-y-4 custom-scrollbar`}
        style={{
          height: openedSection ? `${contentHeight}px` : "0px"
        }}
      >
        {id === "policyHolderConstant" &&
          overviewConstants?.policyHolderConstant.map((obj: any, index: number) => {
            if (!details?.docEyeOutput?.[obj.key]) return;
            return (
              <>
                <div key={index} className="flex justify-between ">
                  <div className="flex flex-col md:flex-row items-start md:items-center gap-2">
                    <p className="font-semibold text-sm md:text-base min-w-32 md:min-w-52 ">{obj.title}</p>
                    <p className="text-[#00000099] text-sm md:text-base font-medium w-52 md:w-full ">{details?.docEyeOutput?.[obj.key]}</p>
                  </div>
                  <div className="flex items-center min-w-[10%]">
                    <button onClick={() => setEditTableField((prev: any) => [...prev, obj.title])} className="bg-[#FFB8004D] flex gap-2 rounded-[5px] items-center px-2 py-1">
                      <img src="/assets/edit-icon.svg" />
                      <p className="text-xs font-medium">Edit</p>
                    </button>
                  </div>
                </div>
                {editableField.includes(obj.title) && (
                  <div className="w-64">
                    <FormInput value={values?.[obj.key]} type="text" name={obj.key} onChange={handleChange} label={obj.label} />
                  </div>
                )}
                {index !== overviewConstants?.policyHolderConstant.length - 1 && <p className="border-[1px] border-[#DDDDDD] border-dashed"></p>}
              </>
            );
          })}

        {id === "insuredDetails" && (
          <div className="border-[1px] border-[#F5E6DA] mb-5 custom-scrollbar">
            {details?.docEyeOutput?.insuredDetails &&
              details?.docEyeOutput?.insuredDetails.map((obj: any, index: number) => {
                return (
                  <>
                    <div className="bg-[#F8F0E9] text-[15px] font-extrabold p-2 md:p-4 rounded-t-[4px]"> Insured {index + 1}</div>
                    <div className="bg-[#FFF9F4] space-y-4 p-2 md[-4] rounded-b-[4px]  border-[1px] border-[#F5E6DA]" key={index}>
                      {overviewConstants.insuredDetails?.map((x: any, i: number) => {
                        if (!obj?.[x.key]) return;
                        return (
                          <>
                            <div key={i} className="flex justify-between">
                              <span className="flex flex-col md:flex-row items-start md:items-center gap-2">
                                <p className="font-semibold text-sm md:text-base min-w-64">{x.title}</p>
                                <p className="text-[#00000099] text-sm md:text-base font-medium w-52 md:w-80">{obj?.[x.key]}</p>
                              </span>
                              <div className="flex items-center">
                                <button onClick={() => setEditTableField((prev: any) => [...prev, x.title])} className="bg-[#FFB8004D] flex gap-2 rounded-[5px] items-center px-2  py-1">
                                  <img src="/assets/edit-icon.svg" />
                                  <p className="text-xs font-medium">Edit</p>
                                </button>
                              </div>
                            </div>
                            {editableField.includes(x.title) && (
                              <div className="w-64">
                                <FormInput value={values?.insuredDetails[index]?.[x.key]} name={x.key} onChange={(e: any) => setFieldValue(`insuredDetails[${index}].${x.key}`, e.target.value)} type="text" label={x.label} />
                              </div>
                            )}
                            {i !== overviewConstants.insuredDetails - 1 && <p className="border-[1px] border-[#DDDDDD] border-dashed"></p>}
                          </>
                        );
                      })}
                    </div>
                  </>
                );
              })}
          </div>
        )}

        {id === "nomineeDetails" &&
          details?.docEyeOutput?.nomineeDetails &&
          details?.docEyeOutput?.nomineeDetails.map((obj: any, index: number) => {
            return overviewConstants.nomineeDetails?.map((x: any, i: number) => {
              if (!obj?.[x.key]) return;
              return (
                <>
                  <div key={i} className="flex justify-between">
                    <div className="flex flex-col md:flex-row items-start md:items-center gap-2">
                      <p className="font-semibold min-w-64">{x.title}</p>
                      <p className="text-[#00000099] font-medium">{obj?.[x.key]}</p>
                    </div>
                    <div className="flex items-center">
                      <button onClick={() => setEditTableField((prev: any) => [...prev, x.title])} className="bg-[#FFB8004D] flex gap-2 rounded-[5px] items-center px-2 py-1">
                        <img src="/assets/edit-icon.svg" />
                        <p className="text-xs font-medium">Edit</p>
                      </button>
                    </div>
                  </div>
                  {editableField.includes(x.title) && (
                    <div className="w-64">
                      <FormInput value={values?.nomineeDetails[index]?.[x.key]} name={x.key} onChange={(e: any) => setFieldValue(`nomineeDetails[${index}].${x.key}`, e.target.value)} type="text" label={x.label} />
                    </div>
                  )}
                  {i !== overviewConstants.nomineeDetails - 1 && <p className="border-[1px] border-[#DDDDDD] border-dashed"></p>}
                </>
              );
            });
          })}
      </div>
    </>
  );
};

export default OverViewCard;
