import { RouterProvider, createBrowserRouter } from "react-router-dom";
import React, { Suspense } from "react";
import allRoutes from "./routes/routes";
// import { getDataInLocalStorage, removeDataInLocalStorage } from "./helpers/Utils";

const routes = createBrowserRouter(allRoutes);
const App: React.FC = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div className="lg:pl-4 lg:flex lg:flex-col lg:max-w-full lg:mx-auto">
        <RouterProvider router={routes} />
      </div>
    </Suspense>
  );
};

export default App;
