// import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import { getPolicyListData } from "services/request";
// import { handleSelectPolicyListData } from "store/user/user.slice";
import policyListTag from "./policyListTag.svg";
import policyTagBlu from "./PolicyTagBlu.svg";
import PolicyTagOrg from "./PolicyTagOrg.svg";

interface PolicyListData {
  _id: number;
  img: string;
  insuranceCompanyId: insuranceCompanyId;
  owner: owner;
  policyTypeId?: policyTypeId;
  qualityCheckStatus: string;
  policyNumber: string;
  nick_name: string;
  kypPdfUrl: string;
  qualityCheck: boolean;
  isDetailedKyp: boolean;
  kypPaymentStatus: string;
}
type insuranceCompanyId = {
  _id: string;
  name: string;
};
type owner = {
  name: string;
};

type policyTypeId = {
  name: string;
};

interface PolicyListProps {
  policyListData: PolicyListData[];
}

export const PolicyList = ({ policyListData }: PolicyListProps) => {
  const navigate = useNavigate();
  // const dispatch = useDispatch();

  const handleDownload = (kypPdfUrl: string) => {
    fetch(kypPdfUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.setAttribute("download", "policyFile.pdf");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => console.error("Download failed:", error));
  };

  const getBackgroundColor = (policyType: string | undefined) => {
    switch (policyType) {
      case "Life Insurance":
        return "bg-custom-gradient-org";
      case "Health Insurance":
        return "bg-custom-gradient-g";
      default:
        return "bg-custom-gradient-blu";
    }
  };

  const getBorderColor = (policyType: string | undefined) => {
    switch (policyType) {
      case "Life Insurance":
        return "border-org";
      case "Health Insurance":
        return "border-g";
      default:
        return "border-blu";
    }
  };

  const getPolicyListTag = (policyType: string | undefined) => {
    switch (policyType) {
      case "Life Insurance":
        return PolicyTagOrg;
      case "Health Insurance":
        return policyListTag;
      default:
        return policyTagBlu;
    }
  };

  const getImgUrl = (policyType: string | undefined) => {
    switch (policyType) {
      case "Life Insurance":
        return "assets/PolicyListHeartLogo.svg";
      case "Health Insurance":
        return "assets/PolicyListHealthLogo.svg";
      default:
        return "assets/PolicyListGeneral.svg";
    }
  };
  const getRetangleImg = (policyType: string | undefined) => {
    switch (policyType) {
      case "Life Insurance":
        return "assets/RectangleOrg.png";
      case "Health Insurance":
        return "assets/Rectangle.svg";
      default:
        return "assets/Rectangle.svg";
    }
  };

  const handleListData = async (id: any, qualityCheckStatus: string, isDetailedKyp: boolean, kypPdfUrl: string, kypPaymentStatus: string, policyTypeId: any) => {
    if (!["Quality Check Ready", "Quality Check Done"].includes(qualityCheckStatus)) {
      toast.success("Your Quality Check Result will be available in 24 hours.");
      return;
    }

    if (["Quality Check Ready", "Quality Check Done"].includes(qualityCheckStatus) && !isDetailedKyp && ["PAID", "WAIVEOFF"].includes(kypPaymentStatus)) return navigate("/result/" + id);
    else if (["Quality Check Ready", "Quality Check Done"].includes(qualityCheckStatus) && isDetailedKyp && ["PAID", "WAIVEOFF"].includes(kypPaymentStatus)) return navigate("/kyp-result/" + id);

    if (["Quality Check Ready", "Quality Check Done"].includes(qualityCheckStatus) && !isDetailedKyp && policyTypeId?._id !== "3030b68f-82e9-4dfb-b2b8-82b743d0692a" && kypPaymentStatus === "PENDING") return navigate("/policy-details/" + id);
    else if (["Quality Check Ready", "Quality Check Done"].includes(qualityCheckStatus) && policyTypeId?._id === "3030b68f-82e9-4dfb-b2b8-82b743d0692a" && kypPaymentStatus === "PENDING") return navigate("/health-policy/" + id);

    // if (!kypPdfUrl) return navigate("/health-policy/" + id);

    // const data = await getPolicyListData(id);
    // dispatch(handleSelectPolicyListData(data?.data));
    // if (data.success && Object.entries(data.data).length !== 0) {
    //   if (!data.data?.isDetailedKyp) navigate("/result/" + id);
    //   else navigate("/kyp-result/" + id);
    // }
  };

  return (
    <>
      {policyListData.map(({ insuranceCompanyId, qualityCheck, qualityCheckStatus, owner, policyNumber, nick_name, policyTypeId, kypPdfUrl, _id, isDetailedKyp, kypPaymentStatus }, indx) => (
        <div key={indx} onClick={() => handleListData(_id, qualityCheckStatus, isDetailedKyp, kypPdfUrl, kypPaymentStatus, policyTypeId)} className={`md:w-[99%] m-auto  min-h-[132px] mb-[12px] rounded-[8px] ${getBorderColor(policyTypeId?.name || "-")} border font-custom relative ${getBackgroundColor(policyTypeId?.name || "-")} cursor-pointer`}>
          {/* Header Section */}
          <div className="">
            <div className="flex gap-2 items-center">
              <div className="flex items-center justify-center -m-1.5">
                <img src="assets/policyListLogo.svg" alt="Policy List Logo" className="relative" />
                <img src={getImgUrl(policyTypeId?.name || "-")} alt={`Logo`} className="absolute pr-2" />
              </div>
              <div>
                <p className="text-black-700 truncate w-52 sm:w-full h-auto font-semibold md:font-bold">{insuranceCompanyId?.name}</p>
              </div>
            </div>
            {/* Rectangle Image positioned at the top right */}
            <img src={`${getRetangleImg(policyTypeId?.name || "-")}`} alt="Rectangle" className="absolute -top-1 -right-1 transform translate-x-2 -translate-y-1" />
            <div className={`flex md:-top-8 relative items-center justify-end -right-[8.4px] top-[68px]`}>
              <div className="absolute">
                <img src={`${getPolicyListTag(policyTypeId?.name || "-")}`} alt="Policy Check Tag" className="relative" />
                <div className="font-bold text-[11px] flex absolute top-[22px] left-4 text-white">{qualityCheckStatus}</div>
              </div>
            </div>
          </div>

          <div className={`border-b-[1px] border-dashed w-full mt-[-0.5px] ${getBorderColor(policyTypeId?.name || "-")}`}></div>

          {/* Details Section */}
          <div className="flex md:flex-row flex-col md:w-[90%] md:max-h-[42px] md:justify-between md:ml-15  mt-3 mb-2 text-sm ">
            <div className="flex  gap-8">
              <div className="flex flex-col ml-8">
                <p className="font-bold">Policy Holder Name</p>
                <p>{owner?.name || ".................."}</p>
              </div>
              <div className="flex flex-col">
                <p className="font-bold">Policy Number</p>
                <p className="pr-2 truncate max-w-32 md:max-w-full">{policyNumber || "0000000000"}</p>
              </div>
            </div>
            <div className={`${(!qualityCheck || !kypPdfUrl) && "invisible"}`}>
              <div className="ml-8 w-[90%] m-auto ">
                {qualityCheckStatus !== "Quality Check Pending" ? (
                  <div
                    className="flex cursor-pointer -ml-7 md:-ml-3"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDownload(kypPdfUrl);
                    }}
                  >
                    <img src="assets/DownloadBtn.svg" alt="Download Btn" />
                  </div>
                ) : (
                  <div>
                    <p className="font-bold">Policy Nick Name</p>
                    <p>{nick_name || "...................................."}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
