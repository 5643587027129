/** @format */

import React, { useEffect, useState } from "react";
import Heading1 from "../../component/semantic/Heading1";
import ContainerOne from "../../component/common/ContainerOne";
import { DeleteIcon, GeneralIcon, HealthIcon, LifeIcon, MotorIcon } from "./IconProvider";
// import { HiMiniInformationCircle } from "react-icons/hi2";
import Select from "react-select";
import ImageIcon from "./image.png";
import device from "./shareIcon.png";
// import digilockerIcon from "./digilockerIcon.png";
import jsPDF from "jspdf";
import BottomContent from "../../layout/BottomContent";
import DarkButton from "../../component/semantic/DarkButton";
import { getCompanyList, getUserProfile, uploadPolicy } from "../../services/request";
import { print } from "../../helpers/Utils";
import { useDispatch, useSelector } from "react-redux";
import { handlePolicy, handlePolicyDetails, handleselectFilesDetails } from "../../store/user/user.slice";
import { RootState } from "../../store/store";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import imageCompression from "browser-image-compression";
import { PDFDocument } from "pdf-lib";
import Modal from "component/common/Modalv2";
import FormInput from "component/semantic/InputOne";

const PolicyDetails: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState({ show: false, password: "", error: false });
  const [activeTab, setActiveTab] = useState(0);
  const { policyDetails, selectFiles, type } = useSelector((state: RootState) => state.user);
  // const [selectedFiles, setSelectedFiles] = useState<any>([]);
  // const [type, setType] = useState(true);
  const [companyList, setCompanyList] = useState<any>([]);
  const [partnerId, setPartnerId] = useState<any>("");

  useEffect(() => {
    const dataFetcher = async () => {
      const res = await getUserProfile();
      setPartnerId(res?.data?.userCreatedBy);
    };

    dataFetcher();
  }, []);

  const handleFileSelected = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files: any = event.target.files;
    const isPasswordProtected = await checkIfPdfIsPasswordProtected(files[0]);
    if (isPasswordProtected) return;
    if (files && files.length > 0) {
      dispatch(handleselectFilesDetails([...files]));
    }
  };

  const handlePDFClick = () => {
    const input: any = document.querySelector("#pdf-picker-hander");
    input.type = "file";
    input.accept = ".pdf";
    // input.capture = "environment";
    input.click();
  };

  const checkIfPdfIsPasswordProtected = async (file: File) => {
    try {
      const pdfData = await file.arrayBuffer();
      await PDFDocument.load(pdfData);
      return false;
    } catch (error: any) {
      if (error.message.includes("encrypted")) {
        setShowPasswordModal((prev) => ({ ...prev, show: true }));
        return true;
      } else {
        console.error("An error occurred while checking the PDF:", error);
        return null;
      }
    }
  };

  // const submitHandler = async () => {
  //   if (!policyDetails?.companyId?.value) {
  //     toast.error('Please select insurance company!!')
  //     return;
  //   }
  //   if (selectFiles.length === 0) {
  //     toast.error('Please uplaod policy!!')
  //     return;
  //   }
  //   setLoader(true)
  //   const formData = new FormData();
  //   if (!type) {
  //     const doc = new jsPDF();
  //     selectFiles.forEach((image: any, index: number) => {
  //       if (index > 0) {
  //         doc.addPage();
  //       }
  //       doc.text(image.name, 10, 10);
  //       const imgData = URL.createObjectURL(image);
  //       doc.addImage(imgData, 'PNG', 10, 20, 180, 160);
  //     });
  //     const pdfBlob = doc.output('blob');
  //     formData.set("file", pdfBlob, 'policy_images.pdf');
  //   } else {
  //     formData.set("file", selectFiles[0]);
  //   }

  //   formData.set("policyNumber", policyDetails.policyNumber )
  //   formData.set("userId",  currentUser?._id)
  //   formData.set("insuranceCompanyId", policyDetails?.companyId?.value )
  //   formData.set("source", 'kyp');
  //   formData.set("owner", currentUser?._id);
  //   // User Id remains
  //   const resData = await uploadPolicy(formData)
  //   if (resData && resData?.success) {
  //     dispatch(handlePolicy(resData?.data))
  //     setLoader(false)
  //     navigate('/loading')
  //   }
  //   setLoader(false)
  //   // dispatch(handleRoute(2))
  // };
  const submitHandler = async () => {
    if (!policyDetails?.companyId?.value) {
      toast.error("Please select insurance company!!");
      return;
    }
    if (selectFiles.length === 0) {
      toast.error("Please upload policy!!");
      return;
    }
    setLoader(true);
    const formData = new FormData();

    // Compress and add images to FormData
    const compressedFiles = [];
    // for (const file of selectFiles) {
    //   const options = {
    //     maxSizeMB: 5, // Maximum size in MB
    //     maxWidthOrHeight: 1920, // Maximum width or height of the image
    //     useWebWorker: true // Use Web Workers for faster compression
    //   };
    //   try {
    //     const compressedFile = await imageCompression(file, options);
    //     compressedFiles.push(compressedFile);
    //   } catch (error) {
    //     console.error('Image compression error:', error);
    //   }
    // }

    if (!type) {
      for (const file of selectFiles) {
        const options = {
          maxSizeMB: 10, // Maximum size in MB
          maxWidthOrHeight: 1920, // Maximum width or height of the image
          useWebWorker: true // Use Web Workers for faster compression
        };
        try {
          const compressedFile = await imageCompression(file, options);
          compressedFiles.push(compressedFile);
        } catch (error) {
          console.error("Image compression error:", error);
        }
      }
      const doc = new jsPDF();
      compressedFiles.forEach((image, index) => {
        if (index > 0) {
          doc.addPage();
        }
        doc.text(image.name, 10, 10);
        const imgData = URL.createObjectURL(image);
        doc.addImage(imgData, "PNG", 10, 20, 180, 160);
      });
      const pdfBlob = doc.output("blob");
      formData.set("file", pdfBlob, "policy_images.pdf");
    } else {
      formData.set("file", selectFiles[0]);
    }

    const currentUserID = localStorage.getItem("userId") || "";
    const selectedPolicyUserId = localStorage.getItem("selectedPolicyHolder");

    formData.set("policyNumber", policyDetails.policyNumber);
    formData.set("userId", currentUserID || "");
    formData.set("insuranceCompanyId", policyDetails?.companyId?.value);
    formData.set("source", "kyp");
    formData.set("kypSrc", "web");
    if (selectedPolicyUserId) formData.set("owner", selectedPolicyUserId);
    else formData.set("owner", currentUserID);

    if (partnerId) formData.set("partnerId", partnerId);

    const couponCode = localStorage.getItem("coupon");
    if (couponCode) formData.set("couponCode", couponCode);

    try {
      const resData = await uploadPolicy(formData);
      if (resData && resData.success) {
        dispatch(handlePolicy(resData.data));
        setLoader(false);
        navigate("/policy-progress/" + resData.data?._id);
        dispatch(handlePolicyDetails({ companyId: undefined }));
        dispatch(handleselectFilesDetails([]));
      }
    } catch (error) {
      console.error("Policy upload error:", error);
      setLoader(false);
    }
  };
  const getCompanyListFunc = async () => {
    try {
      let policyId;

      switch (activeTab) {
        case 0:
          policyId = "3030b68f-82e9-4dfb-b2b8-82b743d0692a";
          break;
        case 1:
          policyId = "864a375b-5fa7-49c7-9f39-2b1fda4674b5";
          break;
        case 2:
          policyId = "2de58f71-ab0a-446f-b5f6-b6e0fa6f46f2";
          break;
        default:
          policyId = "2de58f71-ab0a-446f-b5f6-b6e0fa6f46f2";
      }

      const data = await getCompanyList(policyId);
      setCompanyList(
        data.data
          ?.filter((res: any) => res.docEyeImpStatus)
          .map((res: any) => {
            return { label: res.name, value: res._id };
          })
      );
      // dispatch(handlePolicyDetails({ companyId: '' }))
    } catch (error) {
      print(error);
    }
  };

  useEffect(() => {
    getCompanyListFunc();
  }, [activeTab]);

  const changeHandler = (e: any, name = "") => {
    if (name == "companyId") {
      dispatch(handlePolicyDetails({ companyId: e }));
      return;
    }
  };

  const handleDelete = (index: number) => {
    const newFiles = [...selectFiles];
    newFiles.splice(index, 1);
    dispatch(handleselectFilesDetails(newFiles));
  };
  const handleClearFile = () => {
    const fileInput = document.getElementById("pdf-picker-hander") as HTMLInputElement;
    if (fileInput) {
      fileInput.value = "";
    }
  };
  return (
    <div className=" w-[100%] md:[90%] lg:w-[70%] m-auto py-5 pt-2 mb-[80px]">
      <Heading1 className="font-extrabold text-sm">Policy Details</Heading1>
      <ContainerOne className="!bg-[unset] border border-[#D8DDE9] mt-2 !p-4">
        <Heading1 className="font-semibold text-xs">Insurance Type *</Heading1>
        <div className="w-full md:flex gap-7">
          <div className=" md:w-1/2">
            <div className="w-full flex gap-6">
              <div className=" flex flex-col items-center cursor-pointer">
                <div className={`p-[12px] shadow-icon rounded-[8px] ${activeTab == 0 ? "bg-icon" : ""}`} onClick={() => (dispatch(handlePolicyDetails({ companyId: "" })), setActiveTab(0))}>
                  <HealthIcon activeColor={activeTab === 0 ? "white" : "#CFDAF3"} secondActiveColor={activeTab === 0 ? "#FAAA52" : "#8B9FCD"} />
                </div>
                <Heading1 className={`text-xs font-semibold mt-2 ${activeTab == 0 ? "!font-bold" : ""}`}>Health</Heading1>
              </div>
              <div className="flex flex-col items-center cursor-pointer">
                <div className={`p-[12px] shadow-icon rounded-[8px] ${activeTab == 1 ? "bg-icon" : ""}`} onClick={() => (dispatch(handlePolicyDetails({ companyId: "" })), setActiveTab(1))}>
                  <LifeIcon activeColor={activeTab === 1 ? "white" : "#CFDAF3"} secondActiveColor={activeTab === 1 ? "#FAAA52" : "#8B9FCD"} />
                </div>
                <Heading1 className={`text-xs font-semibold mt-2 ${activeTab == 1 ? "!font-bold" : ""}`}>Life</Heading1>
              </div>
              <div className="flex flex-col items-center cursor-pointer">
                <div className={`p-[12px] shadow-icon rounded-[8px] ${activeTab == 2 ? "bg-icon" : ""}`} onClick={() => (dispatch(handlePolicyDetails({ companyId: "" })), setActiveTab(2))}>
                  <GeneralIcon activeColor={activeTab === 2 ? "white" : "#CFDAF3"} secondActiveColor={activeTab === 2 ? "#FAAA52" : "#8B9FCD"} />
                </div>
                <Heading1 className={`text-xs font-semibold mt-2 ${activeTab == 2 ? "!font-bold" : ""}`}>General</Heading1>
              </div>

              <div className="flex flex-col items-center cursor-pointer">
                <div className={`p-[12px] shadow-icon rounded-[8px] ${activeTab == 3 ? "bg-icon" : ""}`} onClick={() => (dispatch(handlePolicyDetails({ companyId: "" })), setActiveTab(3))}>
                  <MotorIcon activeColor={activeTab === 3 ? "white" : "#CFDAF3"} secondActiveColor={activeTab === 3 ? "#FAAA52" : "#8B9FCD"} />
                </div>
                <Heading1 className={`text-xs font-semibold mt-2 ${activeTab == 3 ? "!font-bold" : ""}`}>Motor</Heading1>
              </div>
            </div>
          </div>
          <hr className="border-[1.5px] mr-7 hidden lg:block border-b-gray-300 w-0 h-full origin-top-right	" />
          <div className="md:w-1/2 mt-4 lg:mt-0 ">
            <div className="">
              <Heading1 className="text-xs font-semibold mb-2">Insurance Company</Heading1>
              <Select
                value={policyDetails.companyId}
                name="companyId"
                className=""
                classNamePrefix="select"
                onChange={(e) => {
                  changeHandler(e, "companyId");
                }}
                options={companyList}
                placeholder="Select Company"
              />
            </div>
            {/* <div className="">
              <Heading1 className="text-xs font-semibold mb-2">Policy Type</Heading1>
              <Select
                value={policyDetails.companyId}
                name="companyId"
                className=""
                classNamePrefix="select"
                isSearchable={true}
                onChange={(e) => {
                  changeHandler(e, "companyId");
                }}
                options={companyList}
                placeholder="Select Company"
              />
            </div> */}
          </div>
        </div>
        {/* <div className="flex gap-2 items-start justify-start">
          <div style={{ fontSize: "18px" }}>
            <HiMiniInformationCircle color="#09090999" />
          </div>
          <Heading1 className="opacity-70 text-xs font-[12px]">Please note If you are unable to find your insurance company, it means that this feature will be available for your insurance company soon.</Heading1>
        </div> */}
        <div className="bg-[#F9F9F9] p-4 -mx-4">
          <Heading1 className="font-extrabold text-sm">Upload Policy </Heading1>
        </div>
        <div className="flex gap-4 p-2 pb-2 pl-4">
          <div className="flex flex-col items-center" onClick={handlePDFClick}>
            <div className="p-4 shadow-icon rounded-[10px]">
              <img src={device} className="w-[32px] h-[32px]" />
            </div>
            <Heading1 className="text-xs font-medium mt-2">Device</Heading1>
          </div>
          <div className="flex flex-col items-center" onClick={() => navigate("/upload")}>
            <div className="p-4 shadow-icon rounded-[10px]">
              <img src={ImageIcon} className="w-[32px] h-[32px]" />
            </div>
            <Heading1 className="text-xs font-medium mt-2">Image</Heading1>
          </div>
          {/* <div className="flex flex-col items-center" onClick={handlePDFClick}>
            <div className="p-4 shadow-icon rounded-[10px]">
              <img src={digilockerIcon} className="w-[32px] h-[32px]" />
            </div>
            <Heading1 className="text-xs font-medium	 mt-2">Digilocker</Heading1>
          </div> */}
        </div>
        <input type="file" id="pdf-picker-hander" accept=".pdf" style={{ display: "none" }} onChange={handleFileSelected} />
        <div className="px-4 pb-2">
          {(selectFiles || []).map((file: any, index: number) => {
            return (
              <div className="flex justify-between items-center mb-2" key={index}>
                <Heading1 className="font-bold text-xs break-words">{file.name}</Heading1>
                <DeleteIcon
                  className=""
                  onClick={() => {
                    handleDelete(index);
                    handleClearFile();
                  }}
                />
              </div>
            );
          })}
        </div>
      </ContainerOne>
      <BottomContent>
        <DarkButton isDisable={loader} color="polifyx" label="CONFIRM" onclick={submitHandler} buttonClassName="px-20" />
      </BottomContent>
      <Modal isOpen={showPasswordModal.show} onClose={() => setShowPasswordModal((prev) => ({ ...prev, show: true }))} className="w-[30rem]">
        <div className="px-5 py-3 text-base space-y-5 font-semibold">
          <p>Your policy is password protected.</p>
          <FormInput label="Enter Password*" value={showPasswordModal.password} placeholder="Password" onChange={(e: any) => setShowPasswordModal((prev: any) => ({ ...prev, password: e.target.value }))} />
        </div>
        <div className="bg-[#F0F4FD] p-4 mt-2 flex justify-around gap-5">
          <button className="flex w-1/2 justify-center mx-auto bg-polifyx  py-3 rounded-lg font-bold " onClick={() => setShowPasswordModal((prev) => ({ ...prev, show: true }))}>
            Proceed
          </button>
        </div>
      </Modal>
    </div>
    // : <UploadImage setUploadScreen={(val: boolean) => dispatch(handleIsUploadScreen(val))} />
  );
};

export default PolicyDetails;
