import Heading1 from "../../component/semantic/Heading1";
import loading from "./loading.gif";
import SocketComponent from "../../socket/socket.component";

const LoadingScreen = () => {
  // const fetchPolicyDetails = async () => {
  //     const resp = await getPolicyDetails(policy?._id)
  //     if (resp && resp?.success) {
  //         dispatch(handleRoute(4))
  //     }
  // }

  // useEffect(() => {
  //     setTimeout(() => {
  //         fetchPolicyDetails()
  //     }, 30000);
  // }, [])

  return (
    <div className="h-[40vh] mt-2">
      <SocketComponent></SocketComponent>
      <div className="">
        <Heading1 className="mb-2 text-center font-extrabold text-sm">Processing Quality Check</Heading1>
        <img src={loading} className="w-[80%] mx-auto" />
      </div>
      <div className="pb-[35vh] absolute bottom-0 right-0 left-0 pt-6 mt-8 bg-[#F9F9F9]">
        <Heading1 className="mb-2 text-center font-bold text-sm">
          We are scanning your policy while <br />
          the upload is in progress. This may take
          <br /> upto 30 seconds.
        </Heading1>
      </div>
    </div>
  );
};

export default LoadingScreen;
