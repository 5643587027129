import Heading1 from "../../component/semantic/Heading1";
// import ContainerOne from "../../component/common/ContainerOne";
import { CgAdd } from "react-icons/cg";
import { PolicyList } from "./policyList";
import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { getPolicyList } from "services/request";
import { useNavigate } from "react-router-dom";

interface PolicyListData {
  _id: number;
  img: string;
  insuranceCompanyId: insuranceCompanyId;
  owner: owner;
  policyTypeId?: policyTypeId;
  qualityCheckStatus: string;
  policyNumber: string;
  nick_name: string;
  kypPdfUrl: string;
  qualityCheck: boolean;
  isDetailedKyp: boolean;
  kypPaymentStatus: string;
}
type insuranceCompanyId = {
  _id: string;
  name: string;
};
type owner = {
  name: string;
};

type policyTypeId = {
  name: string;
};

const ExistingPolicy = () => {
  const [policyListData, setPolicyData] = useState<PolicyListData[]>([]);

  useEffect(() => {
    const dataHandler = async () => {
      const data = await getPolicyList();
      setPolicyData(data.data);
    };
    dataHandler();
  }, []);
  const navigate = useNavigate();

  function addNewPolicyFun() {
    navigate("/policyholder-details");
  }

  return (
    <>
      <div className="mt-4">
        <Heading1 className="font-extrabold w-[25/26] leading-[21px] -mt-3 mb-4 ml-1 block md:hidden">Know Your Policy</Heading1>

        <div className=" max-w-[850px]   mx-2 md:mx-auto  !bg-[unset] border border-[#b8b9ba]	rounded-md ">
          <div className=" h-full pl-0 pr-0 py-5 md:py-10 md px-0 md:px-9 flex flex-col custom-scrollbar justify-between w-full border-[#D8DDE9]">
            <div className="px-5 md:px-0 w-full">
              <Heading1 className="font-extrabold w-[25/26] text-sm  hidden md:block">Know Your Policy</Heading1>

              <div className=" w-[99%]  justify-between items-center block lg:flex">
                <h3 className="font-medium text-sm	 mt-0 md:mt-4  text-black">Safeguard yourself from future claim delays or rejections</h3>
                <div className="border-[1px]  border-dashed block lg:hidden border-b-gray-300 my-4 -mx-5"></div>
                <button className=" border-2 rounded-md border-gray-500 py-3 flex justify-center items-center mt-3 w-full md:w-auto md:px-8 md:py-2 lg:px-8 font-bold text-sm leading-5 " onClick={addNewPolicyFun}>
                  Add New Policy &nbsp;
                  <CgAdd />
                </button>
              </div>

              <div className="relative w-full mt-10 shadow-custom rounded-xl overflow-hidden m-auto mb-4" style={{ paddingTop: "56.25%" }}>
                <ReactPlayer controls url="https://www.youtube.com/watch?v=iVwPRBEEaGM" className="absolute top-0 left-0 rounded-xl " width="100%" height="100%" />
              </div>

              {policyListData && policyListData.length > 0 ? (
                <PolicyList policyListData={policyListData} />
              ) : (
                <div className="w-[99%] m-auto  mt-4  pb-6 pt-2  rounded-lg	 bg-[#F0F4FD]	">
                  <img className="m-auto mt-10" src="/assets/PolicyBook.png" alt="" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExistingPolicy;
