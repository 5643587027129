import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import Heading1 from "../../component/semantic/Heading1";
import OtpInput from "react-otp-input";
import DarkButton from "../../component/semantic/DarkButton";
import BottomContent from "../../layout/BottomContent";
import { getUserDetails, verifyOtp } from "../../services/request";
import useToastWrapper from "../../hooks/useToastWrapper";
import { setDataInLocalStorage } from "../../helpers/Utils";
import { useNavigate } from "react-router-dom";

const OtpWrapper = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(30); // Initial value of timer
  const { showToast } = useToastWrapper();
  const { currentUser } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length === 0) {
      // dispatch(handleRoute(0))
      navigate("/");
    }
  }, []);

  useEffect(() => {
    // Countdown timer
    const intervalId = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      }
    }, 1000);

    // Cleanup function
    return () => clearInterval(intervalId);
  }, [timer]);

  const resendHandler = async () => {
    try {
      const resp = await getUserDetails({
        polHolderName: currentUser.name,
        polHolderPhone: currentUser.phone,
        polHolderEmail: currentUser.email
      });
      if (!resp.success) return;
      setTimer(30); // Reset timer
      showToast("Otp sent successfully", "success");
    } catch (e) {
      showToast("Something went wrong !!!", "error");
    }
  };

  const successHandler = async (e: any) => {
    e.preventDefault();
    try {
      const resp = await verifyOtp({
        polHolderName: currentUser.name,
        polHolderPhone: currentUser.phone,
        polHolderEmail: currentUser.email?.toLowerCase(),
        otp
      });
      if (!resp.success) return;
      if (resp?.data?.token) {
        setDataInLocalStorage("token", resp?.data?.token);
        // dispatch(handleRoute(1))
        navigate("/existing-policy");
      }
    } catch (e) {
      showToast("Something went wrong !!!", "error");
    }
  };

  return (
    <form className="p-2 max-w-[700px] pt-6 m-auto" onSubmit={successHandler}>
      <Heading1 className="font-extrabold text-sm text-center">OTP Verification</Heading1>
      <Heading1 className="font-light text-sm text-center mt-4">Please enter the 6-digit code sent to</Heading1>
      <Heading1 className="font-extrabold text-sm text-center mt-2">+91 {currentUser?.phone || "XXXXXXXXXX"}</Heading1>
      <div className="flex justify-center items-center mt-8">
        <OtpInput inputType="tel" value={otp} onChange={setOtp} numInputs={6} renderSeparator={<span></span>} containerStyle={{ gap: "2px", fontWeight: 800 }} renderInput={(props) => <input {...props} placeholder="*" className="border rounded-2 !w-[50px] h-[58px]" />} />
      </div>
      <Heading1 className="text-xs text-center mt-6">
        Didn't receive code?
        {timer === 0 ? (
          <span className="ml-2 text-[#ED8C21]" onClick={resendHandler}>
            Resend Code
          </span>
        ) : (
          <span className="text-[#FF8B84]">{` Resend in ${timer} seconds`}</span>
        )}
      </Heading1>
      <BottomContent>
        <DarkButton type={"submit"} color="polifyx" label="NEXT" buttonClassName="px-20" />
      </BottomContent>
    </form>
  );
};

export default OtpWrapper;
