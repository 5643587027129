import { makePartnerRequest, makeRequest } from "../defaultValues/constants.services";
import { Apis } from "./apis";

export const getCompanyList = async (payload: any) => {
  return await makeRequest("GET", `${Apis.companyList}?policyTypeId=${payload}`);
};

export const uploadPolicy = async (payload: any, isMultiPart?: boolean) => {
  // eslint-disable-next-line
  return makeRequest("POST", Apis.uploadPolicy, payload, (isMultiPart = true));
};

export const updatePolicy = async (params: string, payload: any) => {
  return makeRequest("PUT", `${Apis.updatePolicy}/${params}`, payload);
};

export const getPolicyDetails = async (id: string) => {
  //get policy doceyedata
  return await makeRequest("GET", `${Apis.policyDetails}/${id}`);
};
//health
export const gethealthQualityDetails = async (payload: any) => {
  return await makeRequest("GET", `${Apis.getHealthQuality}/${payload}`);
};

export const gethealthQualityDetailsV2 = async (payload: any) => {
  return await makeRequest("GET", `${Apis.getHealthQualityV2}/${payload}`);
};
export const healthQualityResult = async (payload: any) => {
  return makeRequest("POST", Apis.healthQualityResult, payload);
};
export const getHealthQualityResult = async (id: any) => {
  return makeRequest("GET", `${Apis.getHealthQualityResult}/${id}`);
};
//Life
export const getLifeQusetions = async (payload: any) => {
  return await makeRequest("POST", `${Apis.getLifeQusetions}`, payload);
};
export const lifeQualityCheck = async (payload: any) => {
  return makeRequest("POST", Apis.lifeQualityCheck, payload);
};
export const lifeQualityCheckResult = async (payload: any) => {
  return makeRequest("POST", Apis.lifeQualityCheckResult, payload);
};
//payment API
export const kypTransaction = async (payload: any) => {
  return makeRequest("POST", Apis.kypTransaction, payload);
};
export const couponCheck = async (payload: any) => {
  return await makeRequest("GET", `${Apis.couponCheck}/${payload}`);
};

export const getUserDetails = async (payload: any) => {
  return await makeRequest("POST", `${Apis.userDetails}`, payload);
};

export const getAppData = async () => {
  return await makeRequest("GET", `${Apis.appData}`);
};

export const verifyOtp = async (payload: any) => {
  return await makeRequest("POST", `${Apis.verifyOtp}`, payload);
};

// partner's request

export const getPartnerData = async (userName: string) => {
  return await makePartnerRequest("GET", `${Apis.getPartnerDetails}/${userName}`);
};

export const getPolicyList: any = async () => {
  return await makeRequest("GET", `${Apis.updatePolicy}?source=kyp`);
};

export const getPolicyListData: any = async (id: string) => {
  return await makeRequest("GET", `${Apis.getHealthQualityResult}/${id}`);
};
export const getPolicyHolder: any = async () => {
  return await makeRequest("GET", Apis.policyHolder);
};

export const addNewUser = async (payload: any) => {
  return await makeRequest("POST", `${Apis.addUser}`, payload);
};

export const getBenefitsSummary = async (payload: any) => {
  return await makeRequest("GET", `${Apis.getBenefitSummary}` + payload);
};

export const getSinglePolicy: any = async (payload: any) => {
  return await makeRequest("GET", `${Apis.updatePolicy}/${payload}`);
};

export const getUserProfile: any = async () => {
  return await makeRequest("GET", `${Apis.getUserProfile}`);
};
