import { useAppSelector } from "hooks/redux_toolkit";
import React, { useState } from "react";
import { ImSpinner2 } from "react-icons/im";
import ReactPlayer from "react-player";
import { useNavigate, useParams } from "react-router-dom";
import SocketComponent from "socket/socket.component";

const PolicyInProgress = () => {
  const hideInfo = useAppSelector((state) => state.user.hideProgressScreenInfo);
  const navigate = useNavigate();
  const params = useParams();

  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const policyDetails = useAppSelector((state) => state.user.uploadedPolicy);

  const handleSubmit = () => {
    if (hideInfo) {
      nextPageHandler();
      // navigate("/health-policy" + "/" + params?.policyId);
    } else navigate("/existing-policy");
  };

  const nextPageHandler = async () => {
    if (policyDetails?.insuranceType?._id === "3030b68f-82e9-4dfb-b2b8-82b743d0692a") return navigate("/health-policy/" + params?.policyId);
    else navigate("/policy-details/" + params?.policyId);
  };

  return (
    <>
      <SocketComponent />
      <div>
        <div className="w-[90%] md:max-w-[850px] min-h-[500px]  mx-auto !bg-[unset] border border-[#D8DDE9]	rounded-2xl  ">
          <div className="w-[90%] m-auto  mt-4  pb-6 pt-2  rounded-lg	 	">
            <ReactPlayer controls url="https://www.youtube.com/watch?v=iVwPRBEEaGM" className="m-auto" width={"100%"} playing={true} />
          </div>
          {!hideInfo && (
            <div className="w-full mt-4 pt-6 bg-[#f7f7f8] mb-6 text-center shadow-inner sm:shadow-[inset_0_20px_6px_rgba(209,206,206,0.2)]" style={{ boxShadow: "inset 0 20px 6px rgba(209, 206, 206, 0.2), inset 0 -13px 10px rgba(174, 170, 170, 0.2)" }}>
              <div className="w-11/12 md:w-4/5 m-auto">
                <h1 className=" w-11/12 m-auto font-extrabold text-base mt-4	 mb-4">We are scanning your policy while the upload is in progress. It generally takes 30-60 seconds.</h1>
                <h3 className="font-extrabold text-xs">You will receive a notification as soon as the results of your quality check are READY!</h3>
                <button className="border rounded-2xl bg-[#FBF2F2] mt-4  mb-7 px-2 py-1 font-bold	" style={{ fontSize: "12px" }}>
                  {" "}
                  Meanwhile you can explore other features of Polifyx
                </button>
              </div>
            </div>
          )}
          <button className={`hidden md:block mx-auto bg-[#FAAA52] text-sm px-9 py-3 mt-7 mb-7 rounded-lg font-bold ${loading && "opacity-50  pointer-events-none"}`} onClick={handleSubmit}>
            {loading ? <ImSpinner2 /> : ""}
            {hideInfo ? "Next" : "Explore Home"}
          </button>
        </div>
        <div className=" block md:hidden mt-14 pb-7 w-full  border-t   rounded-t-3xl shadow-[0_4px_6px_rgba(0,0,0,0.1)]">
          <button className={`block md:hidden mx-auto bg-[#FAAA52] text-sm px-9 py-3 mt-7 mb-7 rounded-lg font-bold ${loading && "opacity-50  pointer-events-none"}`}>
            {" "}
            {loading ? <ImSpinner2 /> : ""}
            {hideInfo ? "Next" : "Explore Home"}
          </button>
        </div>
      </div>

      {/* Modal */}
      {/* {isOpen && (
        <div className="fixed inset-0 m-auto bg-black bg-opacity-50 flex justify-center items-center z-50" onClick={closeModal}>
          <div className="bg-white w-[85%] md:w-[40%] m-auto  border rounded-lg relative" onClick={(e) => e.stopPropagation()}>
            <div className=" mb-1 rounded-lg">
              <div className="w-[90%] m-auto  py-4 flex justify-end">
                <CgCloseO className="w-6 h-6 cursor-pointer" onClick={() => setIsOpen(false)} />
              </div>
            </div>
            <div className="px-2 font-bold text-base">We are currently processing your document and will notify you as soon as the scanning is complete..</div>
            <div className="bg-[#F0F4FD] py-0.5 mt-2">
              <button className="flex mx-auto bg-[#FAAA52] text-sm px-9 py-3 mt-7 mb-7 rounded-lg font-bold " onClick={goToHomeBtnFun}>
                {" "}
                Go To Home
              </button>
            </div>
          </div>
        </div>
      )} */}
    </>
  );
};

export default PolicyInProgress;
