import Heading1 from "../../component/semantic/Heading1";
import checked from "./checkbox.png";
import healthy from "./healthy.png";
import kpy from "./kpy.png";
import Rectangle from "./Rectangle.png";

function HealthyResult({ policy }: any) {
  return (
    <div className="relative">
      <div className="relative h-[150px] overflow-hidden">
        <img src={Rectangle} className={`!w-[100%] absolute z-[1] bg-[#eaf5f2]`} />
        <div className="absolute flex z-[9] right-0 left-0 p-4 justify-start items-center gap-6">
          <img src={kpy} className="" />
          <div className="flex flex-col justify-start items-start">
            <Heading1 className="font-extrabold text-md">{policy?.PolicyHolderName || ""}</Heading1>
            <Heading1 className="font-bold text-sm mt-1">{policy?.InsuranceCompanyName || " "}</Heading1>
            <Heading1 className="font-normal text-xs mt-1">{policy?.PolicyName || ""}</Heading1>
          </div>
        </div>
      </div>
      <div className="p-4 pb-0">
        <Heading1 className="text-[#3C976A] font-bold uppercase">Healthy</Heading1>
        <Heading1 className="font-normal text-xs">Your Policy Status looks Good.</Heading1>
      </div>
      <div className="p-4 flex flex-col gap-4 shadow-bottom pb-6">
        {policy?.qualityCheckContent?.length &&
          policy?.qualityCheckContent?.map((details: any, index: number) => {
            return (
              <div key={index} className="border-[1px] rounded-[8px] border-[#DDF4EC] p-3 relative">
                <div className="w-[78%]">
                  <div className="flex gap-2 items-center">
                    <Heading1 className="text-[14px] font-bold text-sm">{details.Title}</Heading1>
                    <Heading1 className={`text-[12px] font-normal text-xs`} style={{ color: `${details.SubTitle == "High Impact" ? "#FF8B84" : details.SubTitle == "Low Impact" ? "#3C976A" : "#C56D39"}` }}>
                      {details.SubTitle}
                    </Heading1>
                  </div>
                  <div className="mt-2">
                    <Heading1 className="text-[13px] w-[85%] font-normal text-xs">{details.Description}</Heading1>
                  </div>
                </div>
                <img src={healthy} className="absolute top-[20%] right-0 w-[90px]" />
              </div>
            );
          })}
      </div>
      <div className="mt-2 p-4">
        <div className="flex gap-2 justify-start items-center">
          <img src={checked} />
          <Heading1 className="font-bold text-sm">Congratulations, Your Policy Document is Healthy.</Heading1>
        </div>
      </div>
      {/* <div className="p-4">
        <div className="border-[1px] border-[#FFE5C7] p-2 rounded-[8px] bg-[#FEF2E5]">
          <div className="bg-disclaimer pl-2 w-[30%]">Disclaimer</div>
          <Heading1 className="font-normal text-sm">
            Kindly note that correcting inaccurate or missing material
            information related to your policy may result in a change in premium
            or even termination of the policy contract in certain cases.
          </Heading1>
        </div>
      </div> */}
    </div>
  );
}

export default HealthyResult;
