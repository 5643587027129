import { useEffect, useState } from "react";
import BenefitContent from "./BenefitContent";
import NextButton from "./NextButton";
import BenefitTabs from "./BenefitsTab";
import { getBenefitsSummary } from "services/request";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "hooks/redux_toolkit";
import { useDispatch } from "react-redux";
import { handleBenefitsSummaryData } from "store/user/user.slice";

const BenefitSummary = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const benefitsSummaryData = useAppSelector((state) => state.user.benefitsSummaryData);
  const param = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { hospitalisationBenefits, otherBenefits, waitingPeriodAndSubLimits, exclusions } = benefitsSummaryData;
  const tabs = [
    { name: "Hosp. Benefits", key: "hospitalisationBenefits" },
    { name: "Other Benefits", key: "otherBenefits" },
    { name: "Waiting Period", key: "waitingPeriodAndSubLimits" },
    { name: "Exclusion", key: "exclusions" }
  ];

  const handleTabChange = (index: number) => {
    setSelectedIndex(index);
  };

  const handleNextClick = () => {
    setSelectedIndex((prevIndex) => (prevIndex + 1) % tabs.length);
  };

  useEffect(() => {
    const BenefitsDataHandler = async () => {
      const data = await getBenefitsSummary(param?.policyId);
      dispatch(handleBenefitsSummaryData(data?.data));
    };
    BenefitsDataHandler();
  }, [dispatch, param]);

  // Get the data to pass based on the selected tab
  const getContentData = () => {
    switch (selectedIndex) {
      case 0:
        return hospitalisationBenefits;
      case 1:
        return otherBenefits;
      case 2:
        return waitingPeriodAndSubLimits;
      case 3:
        return exclusions;
      default:
        return null;
    }
  };

  return (
    <div className="grid justify-center overflow-auto md:mt-0 mt-4">
      {/* {benefitsSummaryData && <>Benefits Summary</>} */}
      <div className="p-5 justify-center md:border-[1px] custom-scrollbar rounded-tr-lg rounded-tl-lg bg-inherit items-center md:mt-5 flex flex-col -mt-6">
        <div className="flex flex-col">
          <div className="font-extrabold text-sm mb-5">Benefits Summary</div>
          <div className="flex-col justify-between w-[100%]">
            <BenefitTabs selectedIndex={selectedIndex} tabs={tabs} onTabSelect={handleTabChange} />
            <BenefitContent selectedTab={tabs[selectedIndex]} contentData={getContentData()} />
          </div>
        </div>
      </div>
      {selectedIndex !== tabs.length - 1 ? (
        <NextButton onNextClick={handleNextClick} nextTab={tabs[(selectedIndex + 1) % tabs.length].name} />
      ) : (
        <div className="border border-gray-300  text-center  h-28 bg-white p-2">
          {/* <button onClick={() => navigate("/policyholder-overview/" + params?.policyId)} className="text-center mt-3 border border-gray-300 bg-orange-300 p-2 w-40 rounded-lg font-bold"> */}
          <button className="text-center mt-3 border border-gray-300 bg-orange-300 p-2 w-40 rounded-lg font-bold" onClick={() => navigate("/kyp-result/" + param?.policyId)}>
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default BenefitSummary;
