import axios from "axios";
// import { getCurrentUser } from "../helpers/Utils";
import { HttpError } from "../helpers/error";
import { toast } from "react-toastify";

const env = process.env.REACT_APP_ENV || "dev";

let backendApi,
  // agentApi = {},
  webDomain = "http://localhost:5500",
  partnerApi;

switch (env) {
  case "dev":
    backendApi = "https://api.dev.insurancesamadhan.com";
    // agentApi = "https://agentapi.dev.insurancesamadhan.com";
    webDomain = "http://localhost:5500";
    partnerApi = "https://partnersapi.dev.insurancesamadhan.com";
    break;
  case "stage":
    backendApi = "https://api.stage.insurancesamadhan.com";
    // agentApi = "https://agentapi.stage.insurancesamadhan.com";
    webDomain = "https://stage.insurancesamadhan.com";
    partnerApi = "https://partnersapi.stage.insurancesamadhan.com";
    break;
  case "prod":
    backendApi = "https://api.insurancesamadhan.com";
    // agentApi = "https://agentapi.insurancesamadhan.com";
    webDomain = "https://insurancesamadhan.com";
    partnerApi = "https://partnersapi.insurancesamadhan.com";
    break;
  default:
    backendApi = "http://localhost:3999";
    // agentApi = "http://localhost:3000";
    webDomain = "http://localhost:5500";
    partnerApi = "https://partnersapi.dev.insurancesamadhan.com";
}

export const baseUrl = backendApi;
export const partnerUrl = partnerApi;
export const agentApiPath = process.env.REACT_APP_BASE_URL_AGENT;
export const webUrl = webDomain;

type RequestMethod = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

export async function makeRequest(method: RequestMethod = "GET", url: string, data?: any, isMultiPart = false) {
  const config = {
    method,
    url: baseUrl + url,
    data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token") || ""}`,
      "Content-Type": isMultiPart ? "multipart/form-data" : "application/json",
      usertype: "user"
    }
  };
  try {
    const response = await axios(config);
    if (response.status < 200 || response.status >= 300) {
      throw new HttpError(response.status, "HTTP error");
    }
    return response.data;
  } catch (error: any) {
    if (error?.response?.data?.message !== "Health Quality Data Not Found!!!") toast.error(error?.response?.data?.message || "Something went wrong !!!");
    return { success: false, message: error?.response?.data?.message || "Something went wrong !!!" };
  }
}
export async function makePartnerRequest(method: RequestMethod = "GET", url: string, data?: any, isMultiPart = false) {
  const config = {
    method,
    url: partnerUrl + url,
    data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token") || ""}`,
      "Content-Type": isMultiPart ? "multipart/form-data" : "application/json",
      usertype: "user"
    }
  };
  try {
    const response = await axios(config);
    if (response.status < 200 || response.status >= 300) {
      throw new HttpError(response.status, "HTTP error");
    }
    return response.data;
  } catch (error: any) {
    toast.error(error?.response?.data?.message || "Something went wrong !!!");
    return { success: false, message: error?.response?.data?.message || "Something went wrong !!!" };
  }
}
export const playStoreApkLink = "https://play.google.com/store/apps/details?id=com.insurancesamadhan.polifyx&hl=en_IN&gl=US";
export const appStoreApkLink = "https://apps.apple.com/in/app/polifyx/id1623606564";
