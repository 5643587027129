import { MdOutlineEmail, MdOutlineLocalPhone } from "react-icons/md";
import { IoArrowBack } from "react-icons/io5";
import { IoLogoWhatsapp } from "react-icons/io";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { useNavigate } from "react-router-dom";

function Navbar() {
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user);
  const partnerLogo = user?.partnerDetails?.partnerLogo;

  const backHandler = () => {
    if (window.location.pathname === "/loading") {
      navigate("/policy");
      return;
    }
    if (window.location.pathname === "/policy") {
      navigate("/");
      return;
    }
    if (window.location.pathname === "/policy-details") {
      navigate("/policy");
      return;
    }
    if (window.location.pathname === "/result") {
      navigate("/");
      return;
    }
    if (window.location.pathname === "/tryAgain") {
      navigate("/");
      return;
    }
    navigate(-1);
  };
  const handleMail = () => {
    window.location.href = "mailto:corporate@insurancesamadhan.com";
  };
  const handlePhone = () => {
    window.location.href = "tel:9513631312";
  };

  const handleWhatsApp = () => {
    window.location.href = "https://wa.me/+919910998252";
  };
  return (
    <nav className="bg-white shadow-navbar">
      <div className="flex justify-between items-center px-3 md:px-10">
        <div className="items-center hidden ">
          {window.location.pathname !== "/" && <IoArrowBack onClick={backHandler} fontSize={"20px"} />}
          {partnerLogo && <img src={partnerLogo} alt="logo" className="h-8 w-8" />}
        </div>
        <div className="my-5 " onClick={() => (localStorage.getItem("userId") ? navigate("/existing-policy") : navigate("/"))}>
          <img src="/assets/insa.png" alt="logo" className="h-8 w-40 cursor-pointer" />
        </div>
        <div className="items-center flex  mr-3">
          <div className="flex items-center cursor-pointer transition-transform duration-200 hover:scale-105  p-1 md:p-2" onClick={handleMail}>
            <MdOutlineEmail size={"22px"} />
            <span className="lg:block hidden">&nbsp; corporate@insurancesamadhan.com</span>
          </div>
          <div className="flex items-center ml-3 cursor-pointer  hover:scale-105 relative p-1 md:p-2">
            <MdOutlineLocalPhone size={"22px"} onClick={handlePhone} />
            <span className="lg:block hidden"> &nbsp; +91 9513631312</span>
          </div>
          <div className="flex items-center ml-3 cursor-pointer  hover:scale-105 p-1 md:p-2" onClick={handleWhatsApp}>
            <IoLogoWhatsapp size={"22px"} />
            <span className="lg:block hidden">&nbsp; +91 9910998252</span>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
