import Modal from "component/common/Modalv2";
import { useAppSelector } from "hooks/redux_toolkit";
import React, { FC } from "react";

interface ModalProps {
  isOpen: boolean;
  onClose: any;
}

const ModalPopup: FC<ModalProps> = ({ isOpen, onClose }) => {
  const policyData = useAppSelector((state) => state.user.healthQualityData);

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="w-[30rem]">
      <div className="px-5 py-3 text-base space-y-5 font-semibold">
        <p>This feature enables you to review critical details, coverages and exclusions in your insurance policy, ensuring protection against future claim-related issues such as rejections or delays. </p>
        {policyData?.policyId?.kypFeeAmount !== "0" ? (
          <p>
            A nominal service <span className="font-extrabold">fee of Rs.{policyData?.policyId?.kypFeeAmount}</span> will be charged for generating the results.
          </p>
        ) : (
          ""
        )}
      </div>
      <div className="bg-[#F0F4FD] p-4 mt-2 flex justify-around gap-5">
        <button className="flex w-1/2 justify-center mx-auto bg-polifyx  py-3 rounded-lg font-bold " onClick={onClose}>
          Proceed
        </button>
      </div>
    </Modal>
  );
};

export default ModalPopup;
