import React, { useEffect, useState } from "react";
import OverViewCard from "./OverViewCard";
import { couponCheck, gethealthQualityDetailsV2, healthQualityResult, kypTransaction } from "services/request";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import tickIcon from "../questionare/Icon.png";
import insaLogo from "./insaLogoWithoutText.png";

const OverView = () => {
  const [policyDetails, setPolicyDetails] = useState<any>("");
  const [couponCode, setCouponCode] = useState("");
  const [error, setError] = useState("");
  const [focused, setIsFocused] = useState(false);
  const [isKYPFree, setKYPFree] = useState<undefined | boolean>(undefined);
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const navigate = useNavigate();

  const existingCouponCode = localStorage.getItem("coupon");

  useEffect(() => {
    const fetchPolicyDetails = async () => {
      const data = await gethealthQualityDetailsV2(params?.policyId);
      if (data?.success && data?.data?.policyId?.kypFeeAmount === "0") setKYPFree(true);
      setPolicyDetails(data?.data);
    };
    fetchPolicyDetails();
  }, [params?.policyId]);

  const { values, handleChange, setFieldValue, handleSubmit }: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      policyHolderName: policyDetails?.docEyeOutput?.policyHolderName,
      phone: policyDetails?.docEyeOutput?.phone,
      email: policyDetails?.docEyeOutput?.email,
      address: policyDetails?.docEyeOutput?.address,
      nomineeDetails: policyDetails?.docEyeOutput?.nomineeDetails || [],
      insuredDetails: policyDetails?.docEyeOutput?.insuredDetails || []
    },
    onSubmit: async (values) => {
      const payload = { userInput: values, advanced: true, policyId: params?.policyId };
      const data = await healthQualityResult(payload);
      setLoading(false);
      if (data?.success && data?.data?.isDetailedKyp) navigate("/benefits-summary/" + params?.policyId);
      else navigate("/kyp-result/" + params?.policyId);
    }
  });

  const handleCouponChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCouponCode(value);
    setError("");
  };

  const handleRedeem = async () => {
    if (couponCode.trim() === "") return;
    try {
      const responseData = await couponCheck(couponCode);
      if (responseData?.success) setKYPFree(true);
      else setKYPFree(false);
    } catch (error) {
      console.log(error);
    }
  };

  async function displayRazorpay(data: any) {
    const options: any = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      amount: data?.amount || 50000,
      name: "Insurance Samadhan",
      description: "Pay for Policies " + params?.policyId,
      image: insaLogo,
      prefill: {
        email: data.email,
        contact: data.phone
      },
      notes: [data.txnId],
      theme: {
        color: "#000000"
      },
      handler: async function () {
        handleSubmit();
        // try {
        //   let checkResult: any = { success: false };
        //   if (policy?.policyTypeId?._id === "864a375b-5fa7-49c7-9f39-2b1fda4674b5") {
        //     checkResult = await lifeQualityCheck({ changeQuestionValues: values, policyId: policy?._id });
        //   } else {
        //     checkResult = await healthQualityResult({ userInput: values, advanced: true, policyId: policy?._id });
        //   }
        //   if (checkResult?.success) {
        //     dispatch(handleDraftDetails(checkResult?.data?.Draft));
        //     // dispatch(handleRoute(6));
        //     navigate("/result");
        //   }
        //   console.log("Payment Successfull");
        // } catch (error) {
        //   console.log("Something went wrong");
        // }
      }
    };
    const paymentObject: any = new (window as any).Razorpay(options);
    paymentObject.open();
  }

  const handlePayment = async () => {
    setLoading(true);

    const payload = {
      policyId: params?.policyId,
      ...(isKYPFree ? { coupon: couponCode } : ""),
      ...(existingCouponCode ? { coupon: existingCouponCode } : "")
    };

    const payment = await kypTransaction(payload);

    if (isKYPFree || ["KYP Fees Already Paid", "Payment Waived off"].includes(payment?.message)) {
      const payload = { userInput: values, advanced: true, policyId: params?.policyId };
      const data = await healthQualityResult(payload);
      setLoading(false);
      if (data?.success && data?.data?.isDetailedKyp) navigate("/benefits-summary/" + params?.policyId);
      else navigate("/kyp-result/" + params?.policyId);
      return;
    }

    if (payment?.data?.payStatus) {
      displayRazorpay(payment?.data || {});
      return;
    }
    //Navigate
    return;
  };

  return (
    <div className="flex justify-center mt-4">
      <div className="w-full lg:w-[70%] xl:w-[50%] border-[1px] rounded-md p-2 md:p-7">
        <div className="w-full">
          <p className="font-extrabold text-base md:text-xl">Policyholder & Insured Overview</p>
        </div>
        <div className="w-[90%] mt-2 md:mt-0">
          <p className="text-sm md:text-lg">
            Review your policy details and click <strong>'Edit'</strong> to make corrections. We'll assist you in informing your insurance company.
          </p>
        </div>
        <div className="w-full">
          <OverViewCard title="Policyholder Details" id="policyHolderConstant" details={policyDetails} values={values} setFieldValue={setFieldValue} handleChange={handleChange} />
          <OverViewCard title="Insured Details" id="insuredDetails" details={policyDetails} values={values} setFieldValue={setFieldValue} handleChange={handleChange} />
          {policyDetails?.docEyeOutput?.nomineeDetails && policyDetails?.docEyeOutput?.nomineeDetails.length > 0 && <OverViewCard title="Nominee Details" id="nomineeDetails" details={policyDetails} values={values} setFieldValue={setFieldValue} handleChange={handleChange} />}{" "}
        </div>
        <div className="py-4 shadow-polifyx w-full  flex flex-col lg:flex-row items-center justify-evenly lg:items-end px-5 gap-3">
          {!existingCouponCode && policyDetails?.policyId?.kypFeeAmount !== "0" ? (
            <div className="w-full ">
              <p>Have a coupon code?</p>
              <div className="flex items-center justify-end relative">
                <input placeholder="Enter Coupon Code" className={`rounded px-4  text-gray-800 border-b-2 border-[#E5E5E5] w-full h-12 focus:outline-none appearance-none ${couponCode.trim() === "" && "bg-[#F3F4F6]"} ${focused && couponCode.trim() !== "" && "bg-[#FAAA521A] border-[#FAAA52]"} ${isKYPFree === true && "bg-[#2D9E7F1A] border-[#85CEBC]"} ${isKYPFree === false && "bg-[#FA517A1A] border-[#FA517A]"}`} value={couponCode} onChange={handleCouponChange} onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)} />
                {isKYPFree ? (
                  <img src={tickIcon} className="absolute z-10 right-4" />
                ) : (
                  <span className={`absolute cursor-pointer py-1 px-4 font-bold text-[#0000004D] ${focused && couponCode.trim() !== "" && "text-[#FAAA52]"}  ${isKYPFree === false && "text-[#FA517A]"}`} onClick={handleRedeem}>
                    Redeem
                  </span>
                )}
              </div>
            </div>
          ) : (
            ""
          )}
          {/* <div> */}
          <button className={`bg-gradient-to-b from-[#FDCC50] to-[#FAAA52] text-sm px-9 w-full py-3 rounded-lg font-bold ${loading && "pointer-events-none"}`} onClick={handlePayment}>
            {loading ? "Loading..." : isKYPFree ? "Proceed to check result" : `Pay Rs. ${policyDetails?.policyId?.kypFeeAmount} to generate the result`}
          </button>
          {/* </div> */}
        </div>
        {error && <p className="text-red-500">{error}</p>}
        {/* {loading && <p className="text-blue-500">Loading your result...</p>}  */}
      </div>
    </div>
  );
};

export default OverView;
