import React, { HTMLProps } from "react";

type HeadingProps = HTMLProps<HTMLDivElement>;

const Heading1: React.FC<HeadingProps> = ({ className = "", children, ...props }) => {
  return (
    <h1 className={`${className} text-base 2xl:text-2xl xl:text-2xl md:text-xl`} {...props}>
      {children}
    </h1>
  );
};

export default Heading1;
