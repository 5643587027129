import { createSlice } from "@reduxjs/toolkit";

const initialState: { currentPage: number; userDetails: any; policyDetails: any; relations: any; type: any; currentUser: any; policy: any; selectFiles: any; partnerDetails: any; isUploadScreen: boolean; draftDetails: any; policyListDetails: any; benefitsSummaryData: any; healthQualityData: any; hideProgressScreenInfo: boolean; uploadedPolicy: any } = {
  currentPage: 0,
  currentUser: {},
  userDetails: {
    policyHolderMobile: "",
    policyHolderEmail: "",
    policyHolderName: ""
  },
  policy: {},
  policyDetails: {
    companyId: ""
  },
  partnerDetails: {},
  isUploadScreen: false,
  draftDetails: {},
  selectFiles: [],
  relations: [],
  type: true,
  policyListDetails: {},
  benefitsSummaryData: {},
  healthQualityData: {},
  hideProgressScreenInfo: false,
  uploadedPolicy: {}
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    handleCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    handleRoute: (state, action) => {
      state.currentPage = action.payload;
    },
    handleUserDetails: (state, action) => {
      state.userDetails = { ...state.userDetails, ...action.payload };
    },
    handlePolicy: (state, action) => {
      state.policy = { ...state.policy, ...action.payload };
    },
    handlePolicyDetails: (state, action) => {
      state.policyDetails = { ...state.policyDetails, ...action.payload };
    },
    handleIsUploadScreen: (state, action) => {
      state.isUploadScreen = action.payload;
    },
    handlePartnerDetails: (state, action) => {
      state.partnerDetails = { ...state.partnerDetails, ...action.payload };
    },
    handleselectFilesDetails: (state, action) => {
      state.selectFiles = [...action.payload];
    },
    handletypeDetails: (state, action) => {
      state.type = action.payload;
    },
    handrelationDetails: (state, action) => {
      state.relations = action.payload;
    },
    handleDraftDetails: (state, action) => {
      state.draftDetails = { ...state.draftDetails, ...action.payload };
    },
    handleSelectPolicyListData: (state, action) => {
      state.policyListDetails = { ...state.policyListDetails, ...action.payload };
    },
    handleBenefitsSummaryData: (state, action) => {
      state.benefitsSummaryData = { ...state.benefitsSummaryData, ...action.payload };
    },
    handleHealthQualityData: (state, action) => {
      state.healthQualityData = action.payload;
    },
    handleShowProgressScreenInfo: (state, action) => {
      state.hideProgressScreenInfo = action.payload;
    },
    handleUploadedPolicy: (state, action) => {
      state.uploadedPolicy = action.payload;
    }
  }
});

export default userSlice.reducer;
export const { handleRoute, handrelationDetails, handleselectFilesDetails, handletypeDetails, handleUserDetails, handleIsUploadScreen, handleCurrentUser, handlePolicyDetails, handlePolicy, handlePartnerDetails, handleDraftDetails, handleSelectPolicyListData, handleBenefitsSummaryData, handleHealthQualityData, handleShowProgressScreenInfo, handleUploadedPolicy } = userSlice.actions;
