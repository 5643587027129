/** @format */

import React, { useEffect, useState } from "react";
import Heading1 from "../../component/semantic/Heading1";
import ContainerOne from "../../component/common/ContainerOne";
import { HealthIcon, LifeIcon } from "./IconProvider";
// import { HiMiniInformationCircle } from "react-icons/hi2";
import Select from "react-select";
// import ImageIcon from './image.png'
// import pdfIcon from './pdf.png'
import BottomContent from "../../layout/BottomContent";
import DarkButton from "../../component/semantic/DarkButton";
import FormInput from "../../component/semantic/InputOne";
import { getCompanyList, getPolicyDetails, updatePolicy } from "../../services/request";
import { print } from "../../helpers/Utils";
// import { useSelector } from "react-redux";
// import { RootState } from "../../store/store";
// import { handleRoute } from "../../store/user/user.slice";
import { useNavigate, useParams } from "react-router-dom";
import { GeneralIcon, MotorIcon } from "pages/policyDetails/IconProvider";

const getPolicyId = (activeTab: number, insuranceId: string) => {
  let policyId, tab;
  switch (activeTab) {
    case 0:
      policyId = "3030b68f-82e9-4dfb-b2b8-82b743d0692a";
      break;
    case 1:
      policyId = "864a375b-5fa7-49c7-9f39-2b1fda4674b5";
      break;
    case 2:
      policyId = "2de58f71-ab0a-446f-b5f6-b6e0fa6f46f2";
      break;
    default:
      policyId = "2de58f71-ab0a-446f-b5f6-b6e0fa6f46f2";
  }

  switch (insuranceId) {
    case "3030b68f-82e9-4dfb-b2b8-82b743d0692a":
      tab = 0;
      break;
    case "864a375b-5fa7-49c7-9f39-2b1fda4674b5":
      tab = 1;
      break;
    case "2de58f71-ab0a-446f-b5f6-b6e0fa6f46f2":
      tab = 2;
      break;
    default:
      tab = 3;
  }

  return { policyId, tab };
};

const InsurancePolicyDetails: React.FC = () => {
  const initialValues = {
    policyName: "",
    insuranceCompanyId: "",
    premiumAmount: "",
    policyNumber: "",
    policyTypeId: "",
    validTill: "",
    sumInsured: "",
    modeOfPayment: ""
  };
  const navigate = useNavigate();
  const policy: any = useParams();
  // const { policy } = useSelector((state: RootState) => state.user);
  const [activeTab, setActiveTab] = useState(0);
  const [companyList, setCompanyList] = useState<any>([]);
  const [insurancePolicyDetails, setInsurancePolicyDetails] = useState<any>(initialValues);
  const [insurancePolicyDetails2, setInsurancePolicyDetails2] = useState<any>(initialValues);

  const getCompanyListFunc = async () => {
    try {
      const data = await getCompanyList(getPolicyId(activeTab, "").policyId);
      setCompanyList(
        data.data?.map((res: any) => {
          return { label: res.name, value: res._id };
        })
      );
      setInsurancePolicyDetails({ ...insurancePolicyDetails, insuranceCompanyId: "" });
      fetchPolicyDetails();
    } catch (error) {
      print(error);
    }
  };

  useEffect(() => {
    getCompanyListFunc();
  }, [activeTab]);

  const fetchPolicyDetails = async () => {
    const resp = await getPolicyDetails(policy?._id);
    const insuranceObj = {
      insuranceCompanyId: { label: resp?.data?.insuranceCompany.name, value: resp?.data?.insuranceCompany._id },
      ...resp?.data,
      premiumAmount: Number(resp?.data?.premiumAmount.replace(/,/g, "")),
      sumInsured: Number(resp?.data?.sumInsured.replace(/,/g, ""))
    };

    setActiveTab(getPolicyId(0, resp?.data?.insuranceType?._id).tab);
    setInsurancePolicyDetails(insuranceObj || initialValues);
    setInsurancePolicyDetails2(JSON.parse(JSON.stringify(insuranceObj || initialValues)));
  };

  const changeHandler = (e: any, name = "") => {
    if (name == "insuranceCompanyId") {
      setInsurancePolicyDetails({ ...insurancePolicyDetails, [name]: e });
      return;
    }
    setInsurancePolicyDetails({ ...insurancePolicyDetails, [e.target.name]: e.target.value });
  };

  const submitHandler = async () => {
    const payload = {
      ...insurancePolicyDetails,
      insuranceCompanyId: insurancePolicyDetails?.insuranceCompanyId?.value,
      policyTypeId: getPolicyId(activeTab, "").policyId
    };
    // console.log();
    delete payload.insuranceCompany;
    delete payload.insuranceType;
    const resp = await updatePolicy(policy?._id, payload);
    if (resp && resp.success) {
      navigate("/question/" + policy?._id);
      // dispatch(handleRoute(4))
    }
    // navigate("/abc")
  };

  return (
    <div className="px-2 py-2 mx-2 mb-[60px] m-auto w-full">
      <div className="md:w-[50%] m-auto w-full">
        <Heading1 className="font-extrabold text-sm">Your Insurance Policy Details</Heading1>
        <ContainerOne className="!bg-[unset] border border-[#D8DDE9] mt-2 !p-4 mr-3 ">
          <Heading1 className="font-semibold text-xs">Insurance Type *</Heading1>
          <div className="flex gap-6">
            <div className="flex flex-col items-center">
              <div
                className={`p-[10px] shadow-icon rounded-[8px] ${activeTab == 0 ? "bg-icon" : ""}`}
                // onClick={() => setActiveTab(0)}
              >
                <HealthIcon activeColor={activeTab === 0 ? "white" : "#CFDAF3"} secondActiveColor={activeTab === 0 ? "#FAAA52" : "#8B9FCD"} />
              </div>
              <Heading1 className="text-xs font-semibold mt-2">Health</Heading1>
            </div>
            <div className="flex flex-col items-center">
              <div
                className={`p-[10px] shadow-icon rounded-[8px] ${activeTab == 1 ? "bg-icon" : ""}`}
                // onClick={() => setActiveTab(1)}
              >
                <LifeIcon activeColor={activeTab === 1 ? "white" : "#CFDAF3"} secondActiveColor={activeTab === 1 ? "#FAAA52" : "#8B9FCD"} />
              </div>
              <Heading1 className="text-xs font-semibold mt-2">Life</Heading1>
            </div>

            <div className="flex flex-col items-center">
              <div
                className={`p-[10px] shadow-icon rounded-[8px] ${activeTab == 2 ? "bg-icon" : ""}`}
                // onClick={() => setActiveTab(1)}
              >
                <GeneralIcon activeColor={activeTab === 2 ? "white" : "#CFDAF3"} secondActiveColor={activeTab === 2 ? "#FAAA52" : "#8B9FCD"} />
              </div>
              <Heading1 className="text-xs font-semibold mt-2">General</Heading1>
            </div>

            <div className="flex flex-col items-center">
              <div
                className={`p-[10px] shadow-icon rounded-[8px] ${activeTab == 3 ? "bg-icon" : ""}`}
                // onClick={() => setActiveTab(1)}
              >
                <MotorIcon activeColor={activeTab === 3 ? "white" : "#CFDAF3"} secondActiveColor={activeTab === 3 ? "#FAAA52" : "#8B9FCD"} />
              </div>
              <Heading1 className="text-xs font-semibold mt-2">Motor</Heading1>
            </div>
          </div>
          <div className="">
            <Heading1 className="text-xs font-semibold">Insurance Company</Heading1>
            <Select
              value={insurancePolicyDetails?.insuranceCompanyId}
              isDisabled={!!insurancePolicyDetails?.insuranceCompanyId}
              name="insuranceCompanyId"
              className=""
              classNamePrefix="select"
              onChange={(e) => {
                changeHandler(e, "insuranceCompanyId");
              }}
              options={companyList}
            />
          </div>
          <FormInput classess="!mt-[-5px]" value={insurancePolicyDetails?.policyName} isDisabled={!!insurancePolicyDetails?.policyName} name="policyName" type="text" placeholder="Plan Name" label="Plan Name" onChange={(e) => changeHandler(e)} />
          <FormInput classess="!mt-[-5px]" value={insurancePolicyDetails?.policyNumber} isDisabled={!!insurancePolicyDetails?.policyName} name="policyNumber" type="text" placeholder="Policy Number" label="Policy Number" onChange={(e) => changeHandler(e)} />
          <div className="flex gap-2">
            <FormInput classess="!mt-[-5px]" value={insurancePolicyDetails?.premiumAmount} isDisabled={!!insurancePolicyDetails2?.premiumAmount} name="premiumAmount" type="number" placeholder="Premium Amount" label="Premium Amount" onChange={(e) => changeHandler(e)} />
            <FormInput classess="!mt-[-5px]" value={insurancePolicyDetails?.sumInsured} isDisabled={!!insurancePolicyDetails2?.sumInsured} name="sumInsured" type="number" placeholder="Sum Assured" label="Sum Assured" onChange={(e) => changeHandler(e)} />
          </div>
          <div className="mt-[-5px]">
            <FormInput value={insurancePolicyDetails?.modeOfPayment} isDisabled={!!insurancePolicyDetails2?.modeOfPayment} name="modeOfPayment" type="text" placeholder="Payment Frequency" label="Premium Payment Frequency" onChange={(e) => changeHandler(e)} />
          </div>
        </ContainerOne>
        <BottomContent>
          <DarkButton color="polifyx" label="NEXT" onclick={submitHandler} buttonClassName="px-20" />
        </BottomContent>
      </div>
    </div>
  );
};

export default InsurancePolicyDetails;
