import React from "react"
import Navbar from "../layout/navbar"

const ComponentWrapper: React.FC<any> = ({ children, className }) => {
    return <>
    <Navbar />
    <div className={`p-2 ${className}`}>{children}</div>
    </>
}

export default ComponentWrapper