import React from "react";

interface BenefitTabsProps {
  selectedIndex: number;
  tabs: any[];
  onTabSelect: (index: number) => void;
}

const BenefitTabs: React.FC<BenefitTabsProps> = ({ selectedIndex, tabs, onTabSelect }) => {
  const getActiveTabs = (tab: string) => {
    switch (tab) {
      case "Hosp. Benefits":
        return "/assets/benefitsLogo.svg";
      case "Other Benefits":
        return "/assets/otherLogo.svg";
      case "Waiting Period":
        return "/assets/waitingLogo.svg";
      case "Exclusion":
        return "/assets/exclusionLogo.svg";
    }
  };
  const getActiveTabsStyle = (tab: string) => {
    switch (tab) {
      case "Hosp. Benefits":
        return "bg-[#F3FCFF] border-[#C4DBE3]";
      case "Other Benefits":
        return "bg-[#EFFEE3] border-[#95B47C]";
      case "Waiting Period":
        return "bg-[#FEFFE3] border-[#C4C678]";
      case "Exclusion":
        return "bg-[#FFF3F1] border-[#E5C4C0]";
    }
  };
  return (
    <div className="md:w-[715px] md:h-24 items-center  w-[353px] h-[90px] flex gap-4 text-sm  md:overflow-hidden overflow-y-scroll custom-scrollbar p-4 md:-ml-4 md:-mt-7 -mt-4">
      {tabs.map((tab, index) => {
        const isActive = index === selectedIndex;
        return (
          <div key={tab.key} onClick={() => onTabSelect(index)} className={`w-[129px] h-[70px] shadow-icon flex items-center justify-center cursor-pointer transition-transform duration-200 ${isActive ? "scale-105" : ""} ${getActiveTabsStyle(tab.name)} rounded-lg border-[1px] ${isActive ? "opacity-100" : "opacity-80"} text-sm font-bold flex justify-center items-center md:w-[162px] md:h-12`}>
            <div className="flex md:flex-row flex-col gap-2 whitespace-nowrap justify-center p-5">
              <img src={getActiveTabs(tab.name)} alt="BenefitsOptions Tab" className={`${isActive ? "opacity-100" : "opacity-50"} w-[23px] h-[20px]`} />
              {tab.name}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default BenefitTabs;
