/** @format */

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Outlet } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <div>
    <Provider store={store}>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} theme="colored" />
      <div className="relative">
        <div className="fixed top-0 left-0 right-0">
          <div className="h-screen overflow-scroll pb-20">
            <App />
          </div>
        </div>
      </div>
      <Outlet />
    </Provider>
  </div>
);
