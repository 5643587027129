import Modal from "component/common/Modalv2";
import React, { FC } from "react";

interface SubLimitProps {
  isOpen: boolean;
  onClose: any;
  content: any;
  heading: string;
}

const SubLimitModal: FC<SubLimitProps> = ({ isOpen, onClose, content, heading }) => {
  console.log(content);
  return (
    <Modal isOpen={isOpen} onClose={onClose} className="w-[35rem] pb-2" headerClassName="bg-[#F0F4FD] -mt-5 py-5 rounded-t-lg" header={<p className="font-bold">{heading}</p>}>
      <div className="pl-5 pt-5 pr-5 max-h-[26rem] overflow-auto custom-scrollbar ">
        <table>
          <tr className="bg-[#F0F4FD] text-sm font-bold p-5 justify-between">
            <td className="w-[80%] p-5">Treatment/Procedures</td>
            <td>Coverage Amount</td>
          </tr>
          {content &&
            content.map((obj: any, index: number) => {
              return (
                  <tr key={index} className="p-5 border-dashed border-[1px] border-l-0 border-r-0">
                  <td className={`flex gap-3  p-2 border-dashed border-[1px] border-b-0 border-l-0 border-r-0 border-t-0 items-center ${index == content.length - 1 && "border-b-2 border-dashed border-[1px]"}`}>
                    <span className="bg-[#C4C678] w-2 h-2 "></span>
                    <div className="font-bold text-sm ">{obj?.treatment}</div>
                  </td>
                  <td className="border-dashed border-[1px] border-b-0 border-r-0 p-2">
                    <p className="bg-[#D7FEF2] border-[1px] border-[#68C2A6] text-xs p-1 items-center rounded-[2px] font-bold">{obj?.coverage1}</p>
                  </td>
                </tr>
              );
            })}
        </table>
      </div>
    </Modal>
  );
};
//   <div key={index} className={`flex gap-3 justify-between items-center py-3 ${"border-[#cccccc] border-dashed  border-t-[1px] "} `}>

//   </div>

export default SubLimitModal;
