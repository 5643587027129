/** @format */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { getHealthQualityResult, getSinglePolicy, lifeQualityCheckResult } from "../../services/request";
import HealthyResult from "./healthyResult";
import CriticalReport from "./criticalResult";
import AttentionReport from "./attentionReport";
import { useParams } from "react-router-dom";

const ResultScreen = () => {
  const { draftDetails } = useSelector((state: RootState) => state.user);
  const [resultData, setResultData] = useState<any>({});
  const [policy, setPolicy] = useState<any>("");

  const params = useParams();

  useEffect(() => {
    fetchSinglePolicy();
  }, [params]);

  const fetchSinglePolicy = async () => {
    const res = await getSinglePolicy(params?.policyId);
    if (res?.success) setPolicy(res?.data);
  };

  const fetchDetails = async () => {
    if (policy?.policyTypeId?._id === "864a375b-5fa7-49c7-9f39-2b1fda4674b5") {
      const data = await lifeQualityCheckResult({ policyId: params?.policyId });
      setResultData(data?.data);
    } else {
      const data = await getHealthQualityResult(params?.policyId);
      setResultData(data?.data);
    }
  };
  useEffect(() => {
    if (policy || params?.policyId) fetchDetails();
  }, [params?.policyId]);

  return (
    <div className="relative w-full md:w-[70%] xl:w-[50%] border-[0px] md:border-[1px] border-[#b8b9ba]  overflow-hidden m-auto mt-0 md:mt-10 rounded-md">
      {resultData?.ResultTitle === "Healthy" ? <HealthyResult policy={resultData} /> : resultData?.ResultTitle === "Critical" ? <CriticalReport policy={resultData} draftDetails={draftDetails} /> : <AttentionReport policy={resultData} draftDetails={draftDetails} />}
      {resultData?.ResultTi}
    </div>
  );
};

export default ResultScreen;
