/** @format */
import React, { useState } from "react";
import Heading1 from "../../component/semantic/Heading1";
import imageIcon from "./imageIcon.png";
import camera from "./camera.png";
import DarkButton from "../../component/semantic/DarkButton";
import ContainerOne from "../../component/common/ContainerOne";
import { HiMiniInformationCircle } from "react-icons/hi2";
import { DeleteIcon } from "./IconProvider";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { handleselectFilesDetails, handletypeDetails } from "../../store/user/user.slice";

const UploadImage = () => {
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleDelete = (index: number) => {
    const newSelectedFiles = [...selectedFiles];
    newSelectedFiles.splice(index, 1);
    setSelectedFiles(newSelectedFiles);
  };

  const handleCameraClick = () => {
    const input: any = document.querySelector("#image-picker-hander");
    input.type = "file";
    input.accept = "image/*";
    input.capture = "environment";
    input.click();
  };

  const handlePhotoLibraryClick = () => {
    const input: any = document.querySelector("#image-picker-hander-library");
    input.type = "file";
    input.accept = "image/*";
    input.click();
  };

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;

    const items = [...selectedFiles];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setSelectedFiles(items);
  };

  const handleFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files: any = event.target.files;
    if (files && files.length > 0) {
      setSelectedFiles([...selectedFiles, ...files]);
    }
  };
  const submitHandler = async () => {
    dispatch(handleselectFilesDetails(selectedFiles));
    dispatch(handletypeDetails(false));
    // setUploadScreen(false)
    navigate("/policy");
  };

  return (
    <div className="p-4 pt-0">
      {selectedFiles && selectedFiles.length > 0 ? (
        <div className="container mb-10">
          <div className="flex justify-betweeen items-center">
            <Heading1 className="text-xs font-semibold">Click on submit button to upload the document.</Heading1>
            <DarkButton label="SUBMIT" buttonClassName="px-10" onclick={submitHandler} color="polifyx" />
          </div>

          <DragDropContext onDragEnd={handleDragEnd}>
            <ContainerOne className="!bg-[unset] border border-[#D8DDE9] mt-2 !p-4 h-[50vh] overflow-y-scroll">
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(2, 1fr)",
                      gap: "16px"
                    }}
                  >
                    {selectedFiles.map((file: any, index: number) => {
                      return (
                        <Draggable key={index} draggableId={`draggable-${index}`} index={index}>
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="relative h-[140px]">
                              <div className="absolute bg-[white] w-[20px] h-[20px] text-xs font-bold flex justify-center items-center rounded-full z-[2] p-2 left-[2px] top-[2px]">{index + 1}</div>
                              <img className="z-[1] rounded-[8px]" src={URL.createObjectURL(file)} alt={`Selected Image ${index + 1}`} style={{ width: "100%", height: "140px" }} />
                              <DeleteIcon className="absolute right-[-8px] bottom-[-4px]" onClick={() => handleDelete(index)} />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </ContainerOne>
          </DragDropContext>
          <div className="flex gap-2 items-start justify-start  p-2 rounded-[8px] bg-[#F0F4FD]">
            <HiMiniInformationCircle width={"40px"} color="#09090999" />
            <Heading1 className="opacity-70 text-xs font-[12px]">Press and hold to drag and drop the image to change sequence</Heading1>
          </div>
        </div>
      ) : (
        <div className="min-h-[50vh] flex flex-col items-center justify-center">
          <div className="bg-[#F0F4FD] p-4 rounded-[10px]">
            <img src={imageIcon} className="w-[30px] h-[30px]" />
          </div>
          <Heading1 className="font-semibold text-sm text-center mt-4">
            Upload images of your policy document <br />
            to Preview and Submit
          </Heading1>
        </div>
      )}
      <div className="fixed bottom-0 right-0 left-0">
        <div className="flex w-full gap-[1px]">
          <div className="w-[50%] flex flex-col justify-center items-center bg-[#DAE4FA] p-6 md:hidden" onClick={handleCameraClick}>
            <img src={camera} className="w-[30px] h-[30px]" />
            <Heading1 className="font-bold mt-2 text-xs">Camera</Heading1>
          </div>
          <div className="w-[50%] md:w-full flex flex-col justify-center items-center bg-[#CEEADD] p-6" onClick={handlePhotoLibraryClick}>
            <img src={imageIcon} className="w-[30px] h-[30px]" />
            <Heading1 className="font-bold mt-2 text-xs">Photo Library</Heading1>
          </div>
          <input type="file" multiple id="image-picker-hander-library" accept="image/*" style={{ display: "none" }} onChange={handleFileSelected} />
          <input type="file" multiple capture="environment" id="image-picker-hander" accept="image/*" style={{ display: "none" }} onChange={handleFileSelected} />
        </div>
      </div>
    </div>
  );
};

export default UploadImage;
